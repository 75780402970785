// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BackendConnectorService} from "~/application/gen/backend-connector.service";
import {DataElementRef} from "~/application/gen/_models/DataElementRef";
import {TelephonUserPaginatedResponse, TelephonUserOutputModel} from "~/components/telephondigitalComp/gen/telephonUser/telephonUser.api";

// @anchor:imports:start
// @anchor:imports:end
// anchor:custom-imports:start
import { TelephonUserPatchOutputModel } from '~/components/telephondigitalComp/ext/telephonUser/telephonUserPatchOutputModel';
// anchor:custom-imports:end

@Injectable({
  providedIn: 'root'
})
export class TelephonUserService {

  dataElementRef: DataElementRef;

  // @anchor:variables:start
  // @anchor:variables:end
  // anchor:custom-variables:start
  // anchor:custom-variables:end

  constructor(
    private backendConnector: BackendConnectorService,
    // @anchor:services:start
    // @anchor:services:end
    // anchor:custom-services:start
    // anchor:custom-services:end
  ) {
    this.dataElementRef = {
      component: 'telephondigitalComp',
      name: 'TelephonUser'
    };
    // @anchor:constructor:start
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    // anchor:custom-constructor:end
  }

  getTelephonUsers(): Observable<TelephonUserPaginatedResponse> {
    return this.backendConnector.findDataElement<TelephonUserPaginatedResponse>(this.dataElementRef, {pageSize: 100}); //pagesize is a quick and dirty fix, TODO refactor
  }

  displayTelephonUser(telephonUser: TelephonUserOutputModel): string {
    return telephonUser.name ?? '';
  }

  filterTelephonUsers(name: string, options: TelephonUserOutputModel[]): TelephonUserOutputModel[] {
    const filterValue = name.toLowerCase();

    return options.filter(option => option.name?.toLowerCase().includes(filterValue));
  }

  // @anchor:methods:start
  // @anchor:methods:end
  // anchor:custom-methods:start
  getCurrentUser(): Observable<TelephonUserOutputModel> {
    return this.backendConnector.extendedGetDataElement<TelephonUserOutputModel>(
      this.dataElementRef,
      'currentTelephonUser'
    );
  }

  getUserByExternalId(externalId: string): Observable<TelephonUserOutputModel> {
    return this.backendConnector.getDataElement<TelephonUserOutputModel>(
      this.dataElementRef,
      externalId
    );
  }

  updateUser(
    externalId: string,
    patch: TelephonUserPatchOutputModel
  ): Observable<void> {
    return this.backendConnector.patch<TelephonUserPatchOutputModel, void>(
      this.dataElementRef,
      externalId,
      patch,
      ''
    );
  }

  // anchor:custom-methods:end
}
