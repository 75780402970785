import {TherapyMaterialVM} from "~/ext/shared/classes/TherapyMaterialVM";
import {ExternalStorageFileVM} from "~/ext/shared/classes/ExternalStorageFileVM";

export class TherapyMediaVM {

  get therapyMaterialVM(): TherapyMaterialVM {
    return this._therapyMaterialVM;
  }

  set therapyMaterialVM(value: TherapyMaterialVM) {
    this._therapyMaterialVM = value;
  }

  get externalStorageFileVM(): ExternalStorageFileVM | undefined {
    return this._externalStorageFileVM;
  }

  set externalStorageFileVM(value: ExternalStorageFileVM | undefined) {
    this._externalStorageFileVM = value;
  }

  private _therapyMaterialVM!: TherapyMaterialVM;
  private _externalStorageFileVM?: ExternalStorageFileVM;
  constructor() {
    this.therapyMaterialVM = new TherapyMaterialVM();
    this.externalStorageFileVM = undefined
  }

  public addExternalStorageFileVM(file: File, blobContainerType: string): void {
    this.externalStorageFileVM = new ExternalStorageFileVM(file, blobContainerType);
  }

  public markAsTouched() {
    this.therapyMaterialVM.markAsTouched();
  }
}
