// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {AuthInterceptor} from './application/gen/auth/auth.interceptor'

import {AlertModule} from "~/ui/gen/alert/alert.module";
import {ConfirmationDialogModule} from "~/ui/gen/confirmation-dialog/confirmation-dialog.module";

// @anchor:imports:start
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend } from '@angular/common/http';
import { AuthModule } from "~/application/gen/auth/auth.module";
// @anchor:imports:end
// anchor:custom-imports:start
import {TelephonAlertModule} from "~/ui/ext/telephonAlert/telephonAlert.module";
import {TelephonFormDialogModule} from "~/ui/ext/telephonFormDialog/telephonFormDialog.module";
import {
  TelephonCreateAssignedTherapyTaskDialogModule
} from "~/ui/ext/telephonCreateAssignedTherapyTaskDialog/telephonCreateAssignedTherapyTaskDialog.module";
import {TelephonConfirmDialogModule} from "~/ui/ext/telephonConfirmDialog/telephonConfirmDialog.module";
import {TelephonAddMediaDialogModule} from "~/ui/ext/telephonAddMediaDialog/telephonAddMediaDialog.module";
import { TelephonAddMaterialDialogModule } from '~/ui/ext/telephonAddMaterialDialog/telephonAddMaterialDialog.module';
import {TherapyMediaVM} from "~/ext/shared/classes/TherapyMediaVM";
import {
  AssignedTherapyTaskInstructionDialogModule
} from "~/ext/assignedTherapyTaskInstructionDialog/assignedTherapyTaskInstructionDialog.module";
import { NgChartsModule } from 'ng2-charts';
import {NgxMaskModule} from "ngx-mask";
import {ChatSocketService} from "~/ext/shared/services/chatSocket.service";
import {CallSocketService} from "~/ext/shared/services/callSocket.service";
// anchor:custom-imports:end

// @anchor:export-functions:start
export function HttpLoaderFactory(http: HttpBackend){
  return new MultiTranslateHttpLoader(http, [
    "assets/ext/i18n/",
    "assets/gen/i18n/",
    // anchor:custom-locale-folders:start
    // anchor:custom-locale-folders:end
  ]);
}
// @anchor:export-functions:end
// anchor:custom-export-functions:start
// anchor:custom-export-functions:end

@NgModule({
  declarations: [
    AppComponent
    // @anchor:module-declarations:start
    // @anchor:module-declarations:end
    // anchor:custom-module-declarations:start
    // anchor:custom-module-declarations:end
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    AlertModule,
    ConfirmationDialogModule,
    // @anchor:module-imports-preRouting:start
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      },
    }),
    AuthModule.forRoot({
      // Here you can add custom configurations.
      // Any undefined config options will use default values.
      // anchor:custom-config:start
      // anchor:custom-config:end
    }),
    // @anchor:module-imports-preRouting:end
    // anchor:custom-module-imports-preRouting:start
    TelephonAlertModule,
    TelephonConfirmDialogModule,
    TelephonCreateAssignedTherapyTaskDialogModule,
    TelephonFormDialogModule,
    TelephonAddMediaDialogModule,
    TelephonAddMaterialDialogModule,
    AssignedTherapyTaskInstructionDialogModule,
    NgChartsModule,
    NgxMaskModule.forRoot(),
    // anchor:custom-module-imports-preRouting:end
    AppRoutingModule,
    // @anchor:module-imports-postRouting:start
    // @anchor:module-imports-postRouting:end
    // anchor:custom-module-imports-postRouting:start
    // anchor:custom-module-imports-postRouting:end
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'nl'},
    // @anchor:module-providers:start
    // @anchor:module-providers:end
    // anchor:custom-module-providers:start
    { provide: TherapyMediaVM },
    { provide: ChatSocketService },
    { provide: CallSocketService },
    // anchor:custom-module-providers:end
  ],
  bootstrap: [
    AppComponent
    // @anchor:module-bootstrap:start
    // @anchor:module-bootstrap:end
    // anchor:custom-module-bootstrap:start
    // anchor:custom-module-bootstrap:end
  ]
})
export class AppModule {
  constructor(
    // @anchor:services:start
    // @anchor:services:end
    // anchor:custom-services:start
    // anchor:custom-services:end
  ) {
    // @anchor:constructor:start
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    // anchor:custom-constructor:end
  }
}
