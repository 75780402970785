// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0

import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {Alert} from "~/application/gen/_models/Alert";

@Component({
  selector: 'nsx-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: Alert,
    public snackBarRef: MatSnackBarRef<AlertComponent>
  ) { }

  get getIcon(): string | undefined {
    switch (this.data.type) {
      case 'success':
        return 'done';
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      case 'info':
        return 'info';
    }
    return undefined;
  }

}
