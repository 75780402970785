<div class="page-wrapper">

  <h1 style="flex-shrink: 1">{{dialogTitle}}</h1>
  <span class="description">{{dialogDescription}}</span>

  <mat-tab-group dynamicHeight mat-align-tabs="center">
    <mat-tab label="{{'assignTaskDialog.existing_tasks' | translate}}">
      <ng-template matTabContent>

        <div class="content">
          <div class="select-task">
            <mat-card>
              <mat-card-subtitle>
                {{"assignTaskDialog.private_library" | translate}}
              </mat-card-subtitle>
              <mat-card-content>
                <form>
                  <mat-form-field>
                    <mat-label>{{"label.search_task" | translate}}</mat-label>
                    <input
                      (keyup)="searchTherapyTask()"
                      [(ngModel)]="this.search"
                      [value]="this.search"
                      matInput
                      name="search"
                      placeholder="{{ 'label.search_task_placeholder' | translate }}"
                    >
                    <button
                      (click)="resetSearchTherapyTask()"
                      *ngIf="this.search"
                      aria-label="Clear"
                      mat-icon-button
                      matSuffix>
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </form>
                <div
                  *ngIf="privateLibraryTherapyTasksBehaviourSubject$ | async as therapyTaskPaginatedResponse"
                  class="therapyTasks">
                  <ng-container *ngIf="selectedTherapyTasks$ | async as selectedTherapyTasks">
                    <div
                      (click)="selectTherapyTask(therapyTask, i)"
                      *ngFor="let therapyTask of therapyTaskPaginatedResponse._embedded.therapyTasks; index as i"
                      [ngStyle]="selectedTherapyTasks[i] ? { border: '2px solid #51c0cf' }: null"
                      class="therapyTask">
                      <mat-card>
                        <mat-card-content>
                          {{therapyTask.title}}
                        </mat-card-content>
                      </mat-card>
                    </div>
                  </ng-container>
                </div>
                <mat-paginator
                  #paginator
                  (page)="handlePageEvent($event)"
                  [hidePageSize]="true"
                  [length]="length"
                  [pageIndex]="pageIndex"
                  [pageSize]="pageSize"
                  [showFirstLastButtons]="true"
                  aria-label="Select page">
                </mat-paginator>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="select-date">
            <span class="user-action-header">{{"assignTaskDialog.choose_start_date" | translate}}</span>
            <div [formGroup]="startDate" class="date-picker">
              <mat-form-field appearance="fill">
                <mat-label>{{"assignTaskDialog.start_date" | translate}}</mat-label>
                <input [matDatepicker]="$any(picker)" [min]="todayDate" formControlName="start" matInput>
                <mat-hint>{{"assignTaskDialog.start_date_hint" | translate}}</mat-hint>
                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="startDate.controls.start.hasError('matStartDateInvalid')">{{"assignTaskDialog.start_date_invalid" | translate}}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="actions">
            <button
              (click)="close()"
              class="mat-raised-button-warn"
              mat-raised-button>
              {{closeText}}
            </button>
            <button
              (click)="save()"
              class="mat-raised-button-primary"
              mat-raised-button>
              {{saveText}}
            </button>
          </div>
        </div>

      </ng-template>
    </mat-tab>
<!--    <mat-tab label="Create new task (not available)" disabled>-->
<!--      <ng-template matTabContent>-->

<!--        Coming Soon-->

<!--      </ng-template>-->
<!--    </mat-tab>-->
  </mat-tab-group>

</div>
