export enum Language {
  ENGLISH = "ENGLISH",
  DUTCH = "DUTCH",
  GERMAN = "GERMAN",
  FRENCH = "FRENCH",
  SPANISH = "SPANISH",
  CATALAN = "CATALAN",
  ITALIAN = "ITALIAN",
}

export enum LanguageCode {
  EN = "EN",
  NL = "NL",
  DE = "DE",
  FR = "FR",
  ES = "ES",
  CA = "CA",
  IT = "IT",
}
