<div class="parent">
  <h2 mat-dialog-title>{{dialogTitle}}</h2>
  <p>{{dialogDescription}}</p>
  <div mat-dialog-content [formGroup]="dialogForms">
    <ng-container *ngFor="let i of iterator">
      <div>
        <mat-form-field style="width:30vw">
          <div style="text-align: center">
            <input
            matInput
            placeholder="{{dialogFormsPlaceholder[i]}}"
            formControlName="{{dialogFormsControlName[i]}}"
            >
          </div>
          <mat-error *ngIf="invalidForm()">
            {{dialogFormsErrorText[i]}}
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button [color]="'primary'" class="mat-raised-button_warn" mat-raised-button (click)="close()">{{closeText}}</button>
    <button [color]="'primary'" class="mat-raised-button_accent" mat-raised-button (click)="save()">{{saveText}}</button>
  </div>
</div>
