<!-- expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0 -->

<div class="alert" fxLayout="row" fxFlexFill fxLayoutGap="10px">
  <ng-container *ngIf="getIcon">
    <mat-icon fxFlex="nogrow" fxFlexAlign="center">{{getIcon}}</mat-icon>
  </ng-container>
  <span style="overflow-wrap: break-word;" fxFlex="auto" fxFlexAlign="center">{{data.message}}</span>
  <button fxFlex="40px" fxFlexAlign="center" mat-icon-button aria-label="Close icon button with a cross icon"
          (click)="snackBarRef.dismiss()">
    <mat-icon>close</mat-icon>
  </button>
</div>
