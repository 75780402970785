<div class="alert" fxFlexFill fxLayout="row" fxLayoutGap="10px">
  <ng-container *ngIf="getIcon">
    <mat-icon fxFlex="nogrow" fxFlexAlign="center">{{getIcon}}</mat-icon>
  </ng-container>
  <span fxFlex="auto" fxFlexAlign="center" style="overflow-wrap: break-word;">{{data.message}}</span>
  <button (click)="snackBarRef.dismiss()" aria-label="Close icon button with a cross icon" fxFlex="40px" fxFlexAlign="center"
          mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
