import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TelephonAddMediaDialogComponent} from "~/ui/ext/telephonAddMediaDialog/telephonAddMediaDialog.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTabsModule} from "@angular/material/tabs";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    TelephonAddMediaDialogComponent,
  ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatTabsModule,
        ReactiveFormsModule,
        TranslateModule,
    ],
  exports: [
    TelephonAddMediaDialogComponent,
  ]
})
export class TelephonAddMediaDialogModule {

}
