// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0

import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from "~env";
import {Config} from "~/config.interface";
declare let __config: Config;

export const authConfig: AuthConfig = {
  issuer: __config.oidcIssuer,
  clientId: __config.oidcClientId,
  responseType: 'code',
  redirectUri: window.location.origin + '/',
  scope: __config.oidcScope,
  sessionChecksEnabled: false,
  showDebugInformation: environment.isDebuggingEnabled,
  clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
};
