import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  AssignedTherapyTaskInstructionModel
} from "~/ext/assignedTherapyTaskInstructionDialog/models/assignedTherapyTaskInstruction.model";

@Component({
  selector: 'assignedTherapyTaskInstructionDialog',
  templateUrl: 'assignedTherapyTaskInstructionDialog.component.html',
  styleUrls: [
    'assignedTherapyTaskInstructionDialog.component.scss'
  ],
})
export class AssignedTherapyTaskInstructionDialogComponent {
  get denyText(): string {
    return this._denyText;
  }

  set denyText(value: string) {
    this._denyText = value;
  }

  get confirmText(): string {
    return this._confirmText;
  }

  set confirmText(value: string) {
    this._confirmText = value;
  }

  get dialogDescription(): string {
    return this._dialogDescription;
  }

  set dialogDescription(value: string) {
    this._dialogDescription = value;
  }

  get dialogTitle(): string {
    return this._dialogTitle;
  }

  set dialogTitle(value: string) {
    this._dialogTitle = value;
  }

  get denied(): boolean {
    return this._denied;
  }

  set denied(value: boolean) {
    this._denied = value;
  }

  get confirmed(): boolean {
    return this._confirmed;
  }

  set confirmed(value: boolean) {
    this._confirmed = value;
  }

  private _confirmed: boolean = true;
  private _denied: boolean = false;
  private _dialogTitle!: string;
  private _dialogDescription!: string;
  private _confirmText!: string;
  private _denyText!: string;

  constructor(
    private readonly dialogRef: MatDialogRef<AssignedTherapyTaskInstructionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: AssignedTherapyTaskInstructionModel,
  ) {
    this.dialogTitle = data.dialogTitle;
    this.dialogDescription = data.dialogDescription;
    this.confirmText = data.confirmText;
    this.denyText = data.denyText;
  }

  public confirm(): void {
    this.dialogRef.close(this.confirmed);
  }

  public deny(): void {
    this.dialogRef.close(this.denied)
  }
}
