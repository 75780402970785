import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {Alert} from "~/application/gen/_models/Alert";

@Component({
  selector: 'nsx-alert',
  templateUrl: './telephonAlert.component.html',
  styleUrls: ['./telephonAlert.component.scss']
})
export class TelephonAlertComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: Alert,
    public snackBarRef: MatSnackBarRef<TelephonAlertComponent>
  ) {
  }

  get getIcon(): string | undefined {
    switch (this.data.type) {
      case 'success':
        return 'done';
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      case 'info':
        return 'info';
    }
    return undefined;
  }
}
