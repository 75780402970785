// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BackendConnectorService} from "~/application/gen/backend-connector.service";
import {DataElementRef} from "~/application/gen/_models/DataElementRef";
import {LanguagePaginatedResponse, LanguageOutputModel} from "~/components/telephondigitalComp/gen/language/language.api";

// @anchor:imports:start
// @anchor:imports:end
// anchor:custom-imports:start
import { LanguageReferenceModel } from '~/components/telephondigitalComp/gen/language/language.api';
import { Language, LanguageCode } from '~/ext/shared/enums/Language';
// anchor:custom-imports:end

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  dataElementRef: DataElementRef;

  // @anchor:variables:start
  // @anchor:variables:end
  // anchor:custom-variables:start
  // anchor:custom-variables:end

  constructor(
    private backendConnector: BackendConnectorService,
    // @anchor:services:start
    // @anchor:services:end
    // anchor:custom-services:start
    // anchor:custom-services:end
  ) {
    this.dataElementRef = {
      component: 'telephondigitalComp',
      name: 'Language'
    };
    // @anchor:constructor:start
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    // anchor:custom-constructor:end
  }

  getLanguages(): Observable<LanguagePaginatedResponse> {
    return this.backendConnector.findDataElement<LanguagePaginatedResponse>(this.dataElementRef, {pageSize: 100}); //pagesize is a quick and dirty fix, TODO refactor
  }

  displayLanguage(language: LanguageOutputModel): string {
    return language.name ?? '';
  }

  filterLanguages(name: string, options: LanguageOutputModel[]): LanguageOutputModel[] {
    const filterValue = name.toLowerCase();

    return options.filter(option => option.name?.toLowerCase().includes(filterValue));
  }

  // @anchor:methods:start
  // @anchor:methods:end
  // anchor:custom-methods:start
  getLanguageCode(language: LanguageOutputModel | LanguageReferenceModel): string {
    switch (language.name) {
      case Language.ENGLISH:
        return LanguageCode.EN;
      case Language.DUTCH:
        return LanguageCode.NL;
      case Language.ITALIAN:
        return LanguageCode.IT;
      case Language.FRENCH:
        return LanguageCode.FR;
      case Language.SPANISH:
        return LanguageCode.ES;
      case Language.CATALAN:
        return LanguageCode.CA;
      case Language.GERMAN:
        return LanguageCode.DE;
      default:
        return LanguageCode.EN;
    }
  }

  getLanguageName(locale: string): Language {
    switch (locale) {
      case 'en':
        return Language.ENGLISH;
      case 'nl':
        return Language.DUTCH;
      case 'de':
        return Language.GERMAN;
      case 'fr':
        return Language.FRENCH;
      case 'es':
        return Language.SPANISH;
      case 'ca':
        return Language.CATALAN;
      case 'it':
        return Language.ITALIAN;
      default:
        return Language.ENGLISH;
    }
  }
  // anchor:custom-methods:end
}
