<div class="search-bar" [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'label.search_title' | translate }}</mat-label>
    <input matInput formControlName="title" type="text" />
  </mat-form-field>

  <!-- Keywords -->
  <mat-form-field appearance="outline">
    <mat-label>{{ 'label.keywords' | translate }}</mat-label>
    <mat-select multiple formControlName="keywords">
      <mat-select-trigger>
        <mat-chip-list>
          <mat-chip *ngFor="let keyword of form.get('keywords')?.value" [removable]="true" (removed)="onKeywordRemoved(keyword)">
            {{ 'keyword.' + keyword | translate }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </mat-select-trigger>

      <mat-option *ngFor="let keyword of keywords" [value]="keyword.value">
        {{ keyword.displayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Language -->
  <mat-form-field appearance="outline">
    <mat-label>{{ 'label.language' | translate }}</mat-label>
    <mat-select formControlName="language">
      <ng-container *ngFor="let option of languages">
        <mat-option [value]="option.value">{{ option.displayName | translate }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <!-- Discipline -->
  <mat-form-field appearance="outline">
    <mat-label>{{ 'label.discipline' | translate }}</mat-label>
    <mat-select formControlName="discipline" (valueChange)="disciplineSelected($event)">
      <ng-container *ngFor="let option of disciplines">
        <mat-option [value]="option.value">{{ option.displayName | translate }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <!-- Subdiscipline -->
  <mat-form-field appearance="outline">
    <mat-label>{{ 'label.subdiscipline' | translate }}</mat-label>
    <mat-select formControlName="subdiscipline">
      <ng-container *ngFor="let option of filteredSubdisciplines">
        <mat-option [value]="option.value">{{ option.displayName | translate }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <!-- Therapy material type -->
  <mat-form-field appearance="outline">
    <mat-label>{{ 'label.therapy_material_type' | translate }}</mat-label>
    <mat-select formControlName="therapyMaterialType">
      <ng-container *ngFor="let option of therapyMaterialTypes">
        <mat-option [value]="option.value">{{ option.displayName | translate }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <div class="btn-container">
    <button class="mat-raised-button_primary" mat-raised-button color="primary" (click)="searchButtonHandler()">
      {{ 'button.search' | translate | uppercase }}
    </button>
    <button [disabled]="!form.dirty" class="mat-raised-button_warn" mat-raised-button color="warn" (click)="removeFilterButtonHandler()">
      {{ 'button.remove_filters' | translate | uppercase }}
    </button>
  </div>
</div>
