// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {environment} from '~env';
import {AuthService} from './auth.service';

import {Config} from "~/config.interface";
declare let __config: Config;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly authService: AuthService
    // @anchor:services:start
    // @anchor:services:end
    // anchor:custom-services:start
    // anchor:custom-services:end
  ) {
    // @anchor:constructor:start
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    // anchor:custom-constructor:end
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // @anchor:auth-pre-intercept:start
    // @anchor:auth-pre-intercept:end
    // anchor:custom-auth-pre-intercept:start
    // anchor:custom-auth-pre-intercept:end
    // Continue request without action if URL is not whitelisted.
    if (!this.isAuthHeaderAllowedOnUrl(request.url)) {
      return next.handle(request);
    }

    const token = this.authService.getAuthorizationHeader();
    if (!token) {
      // No tokens available. Request is going to fail, so don't bother.
      // TODO Show notification/toast with warning instead, or directly navigate user to login page.
      throw new HttpErrorResponse({
        error: "No auth token available! Refresh page and login.",
        url: request.url
      });
    }

    // @anchor:auth-intercept:start
    // @anchor:auth-intercept:end
    // anchor:custom-auth-intercept:start
    // anchor:custom-auth-intercept:end

    return next.handle(
      request.clone({
        setHeaders: {
          Authorization: `${token}`
        }
      })
    );
  }

  /**
   * Only allow Auth header on request if URL is of our API.
   * It is also very important that any login and refresh API call is excluded.
   * This could cause a deadlock where the call is waiting for itself to
   * finish before it is executed.
   */
  private isAuthHeaderAllowedOnUrl(url: string): boolean {
    return url.startsWith(__config.baseUrl) &&
      !environment.authInterceptExclusions.some(excl => url.includes(excl))
  }

  // @anchor:methods:start
  // @anchor:methods:end
  // anchor:custom-methods:start
  // anchor:custom-methods:end
}
