// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0

import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import {Config} from "~/config.interface";
declare let __config: Config;

// anchor:custom-imports:start
// anchor:custom-imports:end

export const oAuthModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [
      __config.baseUrl,
      // anchor:custom-allowed-urls:start
      // anchor:custom-allowed-urls:end
    ],
    sendAccessToken: true,
  }
};
