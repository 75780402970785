// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BackendConnectorService} from "~/application/gen/backend-connector.service";
import {DataElementRef} from "~/application/gen/_models/DataElementRef";
import {TherapyMediaPaginatedResponse, TherapyMediaOutputModel} from "~/components/telephondigitalComp/gen/therapyMedia/therapyMedia.api";

// @anchor:imports:start
// @anchor:imports:end
// anchor:custom-imports:start
// anchor:custom-imports:end

@Injectable({
  providedIn: 'root'
})
export class TherapyMediaService {

  dataElementRef: DataElementRef;

  // @anchor:variables:start
  // @anchor:variables:end
  // anchor:custom-variables:start
  // anchor:custom-variables:end

  constructor(
    private backendConnector: BackendConnectorService,
    // @anchor:services:start
    // @anchor:services:end
    // anchor:custom-services:start
    // anchor:custom-services:end
  ) {
    this.dataElementRef = {
      component: 'telephondigitalComp',
      name: 'TherapyMedia'
    };
    // @anchor:constructor:start
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    // anchor:custom-constructor:end
  }

  getTherapyMedias(): Observable<TherapyMediaPaginatedResponse> {
    return this.backendConnector.findDataElement<TherapyMediaPaginatedResponse>(this.dataElementRef, {pageSize: 100}); //pagesize is a quick and dirty fix, TODO refactor
  }

  displayTherapyMedia(therapyMedia: TherapyMediaOutputModel): string {
    return therapyMedia.externalId ?? '';
  }

  filterTherapyMedias(name: string, options: TherapyMediaOutputModel[]): TherapyMediaOutputModel[] {
    const filterValue = name.toLowerCase();

    return options.filter(option => option.externalId?.toLowerCase().includes(filterValue));
  }

  // @anchor:methods:start
  // @anchor:methods:end
  // anchor:custom-methods:start
  public postTherapyMedia<T, R>(therapyMediaPostInputModel: T): Observable<R> {
    return this.backendConnector.post<T, R>(
      this.dataElementRef,
      therapyMediaPostInputModel
    );
  }

  public getTherapyMediaByTherapyMaterialExternalId(externalId: string): Observable<TherapyMediaPaginatedResponse> {
    return this.backendConnector.querySearchDataElement<TherapyMediaPaginatedResponse>(
      this.dataElementRef,
      undefined,
      {
        ["therapyMaterialExternalId"]: externalId,
      },
    );
  }

  public deleteTherapyMediaByExternalId<T>(externalId: string): Observable<T> {
    return this.backendConnector.delete<T>(
      this.dataElementRef,
      externalId
    );
  }

  public getTherapyMediaByExternalId<T>(externalId: string): Observable<T> {
    return this.backendConnector.getDataElement<T>(this.dataElementRef, externalId);
  }
  // anchor:custom-methods:end
}
