<div class="parent">
<h1 mat-dialog-title>{{dialogTitle}}</h1>
  <div mat-dialog-content>
    <p>{{dialogDescription}}</p>
  </div>
  <div mat-dialog-actions>
    <button class="mat-raised-button_warn" mat-raised-button (click)="deny()">{{denyText}}</button>
    <button class="mat-raised-button_accent" mat-raised-button (click)="confirm()">{{confirmText}}</button>
  </div>
</div>
