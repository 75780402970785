// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';

import {AuthGuard} from './application/gen/auth/auth.guard';
// @anchor:imports:start
// @anchor:imports:end
// anchor:custom-imports:start
// anchor:custom-imports:end

const routes: Routes = [
  // @anchor:paths:start
  // @anchor:paths:end
  // anchor:custom-paths:start
  {
    path: 'login',
    loadChildren: () => import('./pages/ext/telephonLogin/telephonLogin.module').then(m => m.TelephonLoginModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/ext/telephonLayout/telephonLayout.module').then(m => m.TelephonLayoutModule),
    canActivate: [AuthGuard]
  },
  {path: '**', pathMatch: 'full', redirectTo: ''},
  // DON'T LOOK AT PATHS BELOW THEY ARE NO LONGER IN USE
  // anchor:custom-paths:end
  {path: 'login', loadChildren: () => import('./pages/gen/login/login.module').then(m => m.LoginModule)},
  {path: '', loadChildren: () => import('./pages/gen/layout/layout.module').then(m => m.LayoutModule), canActivate: [AuthGuard]},
  {path: '**', pathMatch: 'full', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    // anchor:custom-module-imports:start
    // anchor:custom-module-imports:end
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
