export class TelephonAddMaterialDialogResult {
  private _materialExternalId : string;
  private _materialTitle: string;

  constructor(materialExternalId: string, materialTitle: string) {
    this._materialExternalId = materialExternalId;
    this._materialTitle = materialTitle;
  }


  get materialExternalId(): string {
    return this._materialExternalId;
  }

  set materialExternalId(value: string) {
    this._materialExternalId = value;
  }

  get materialTitle(): string {
    return this._materialTitle;
  }

  set materialTitle(value: string) {
    this._materialTitle = value;
  }
}
