// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0

import {AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage} from "angular-oauth2-oidc";
import {APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf} from "@angular/core";
import {AuthService} from "./auth.service";
import {authAppInitializerFactory} from "./auth-app-initializer.factory";
import {authConfig} from "./auth.config";
import {oAuthModuleConfig} from "./oauth-module.config";

@NgModule(
  {
    imports: [
      OAuthModule.forRoot()
    ],
    providers: [
      AuthService
    ],
  }
)
export class AuthModule {
  static forRoot(
    config: {
      authAppInitializerFactory?: (authService: AuthService) => () => Promise<boolean | void>,
      authConfig?: AuthConfig,
      oAuthModuleConfig?: OAuthModuleConfig,
      oAuthStorage?: OAuthStorage
    }
  ): ModuleWithProviders<AuthModule> {
    if(!config.authAppInitializerFactory){
      config.authAppInitializerFactory = authAppInitializerFactory;
    }
    if(!config.authConfig){
      config.authConfig = authConfig;
    }
    if(!config.oAuthModuleConfig){
      config.oAuthModuleConfig = oAuthModuleConfig;
    }
    if(!config.oAuthStorage){
      config.oAuthStorage = localStorage;
    }
    return {
      ngModule: AuthModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: config.authAppInitializerFactory, deps: [AuthService], multi: true },
        { provide: AuthConfig, useValue: config.authConfig },
        { provide: OAuthModuleConfig, useValue: config.oAuthModuleConfig },
        { provide: OAuthStorage, useValue: config.oAuthStorage},
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it in the AppModule only');
    }
  }
}
