<div class="page-wrapper">
  <h1 style="flex-shrink: 1">{{dialogTitle}}</h1>
  <span class="description">{{dialogDescription}}</span>
  <div class="content">
    <div class="select-task">
      <mat-card>
        <mat-card-subtitle>
          {{"addMediaDialog.media" | translate}}
        </mat-card-subtitle>
        <mat-card-content>
          <form>
            <mat-form-field>
              <mat-label>{{"button.search" | translate}}</mat-label>
              <input
                (keyup)="searchTherapyMaterial()"
                [(ngModel)]="this.search"
                [value]="this.search"
                matInput
                name="search"
              >
              <button
                (click)="resetSearchTherapyMaterial()"
                *ngIf="this.search"
                aria-label="Clear"
                mat-icon-button
                matSuffix>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </form>
          <div
            *ngIf="libraryMaterialBehaviourSubject$ | async as therapyMaterialPaginatedResponse"
            class="therapyTasks">
            <ng-container *ngIf="selectedTherapyMaterials$ | async as selectedTherapyMaterials">
              <div
                (click)="selectTherapyMaterial(therapyMaterial, i)"
                *ngFor="let therapyMaterial of therapyMaterialPaginatedResponse._embedded.therapyMaterials; index as i"
                [ngStyle]="selectedTherapyMaterials[i] ? { border: '2px solid #51c0cf' }: null"
                class="therapyTask">
                <mat-card>
                  <mat-card-content>
                    {{therapyMaterial.title}}
                  </mat-card-content>
                </mat-card>
              </div>
            </ng-container>
          </div>
          <mat-paginator
            #paginator
            (page)="handlePageEvent($event)"
            [hidePageSize]="true"
            [length]="length"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [showFirstLastButtons]="true"
            aria-label="Select page">
          </mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="actions">
      <button
        (click)="close()"
        class="mat-raised-button-warn"
        mat-raised-button>
        {{closeText}}
      </button>
      <button
        (click)="save()"
        class="mat-raised-button-primary"
        mat-raised-button>
        {{saveText}}
      </button>
    </div>
  </div>
</div>
