import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private readonly storage: Storage;

  constructor() {
    try {
      this.storage = localStorage;
    } catch (err) {
      console.warn('Using in-memory storage because browser blocked access to sessionStorage.', err);
      this.storage = new PrivateStorage();
    }
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  storeItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  getAsJson(key: string): unknown {
    const stored = this.getItem(key);
    if (stored === null) {
      return undefined;
    }
    return JSON.parse(stored);
  }

  storeAsJson(key: string, value: unknown): void {
    this.storeItem(key, JSON.stringify(value));
  }
}

class PrivateStorage implements Storage {
  private readonly data = new Map<string, string>();

  get length(): number {
    return this.data.size;
  }

  clear(): void {
    this.data.clear();
  }

  getItem(key: string): string | null {
    return this.data.get(key) ?? null;
  }

  removeItem(key: string): void {
    this.data.delete(key);
  }

  setItem(key: string, value: string): void {
    this.data.set(key, value);
  }

  key(index: number): string | null {
    throw new Error('Method not implemented.');
  }
}
