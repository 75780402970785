// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {AuthService} from './auth.service';
// @anchor:imports:start
// @anchor:imports:end
// anchor:custom-imports:start
import { LocalStorageService } from '~/ext/shared/services/sessionStorage.service';
import { TelephonUserService } from '~/components/telephondigitalComp/gen/telephonUser/_services/telephonUser.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '~/components/telephondigitalComp/gen/language/_services/language.service';

// anchor:custom-imports:end

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  // @anchor:variables:start
  // @anchor:variables:end
  // anchor:custom-variables:start
  // anchor:custom-variables:end

  constructor(
      private router: Router,
      private authService: AuthService,
      // @anchor:services:start
      // @anchor:services:end
      // anchor:custom-services:start
    private readonly localStorage: LocalStorageService,
    private telephonUserService: TelephonUserService,
    private translateService: TranslateService,
    private languageService: LanguageService
      // anchor:custom-services:end
  ) {
    // @anchor:constructor:start
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    // anchor:custom-constructor:end
  }

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // @anchor:canActivate:start
    // @anchor:canActivate:end
    // anchor:custom-canActivate:start
    return this.authService.validateLogin().pipe(
      map((isLoggedIn) => {
        if (isLoggedIn) {
          this.setUserLanguage();
          if (this.localStorage.getAsJson('redirectUrl')) {
            // User was trying to get to a specific url before prompted to log in.
            const redirectUrl: string = <string>(
              this.localStorage.getAsJson('redirectUrl')
            );
            // Delete otherwise we have an infinite loop!
            this.localStorage.removeItem('redirectUrl');
            if (redirectUrl.includes('?')) {
              // We must interpret the queryParam from the string gotten from localStorage.
              const splitRedirectUrl: (string | { [p: string]: string })[] =
                this.splitRedirectUrl(redirectUrl);
              const url: string = <string>splitRedirectUrl[0];
              const queryParam: { [p: string]: string } = <
                { [p: string]: string }
              >splitRedirectUrl[1];
              return this.router.createUrlTree([url], {
                queryParams: queryParam,
              });
            } else {
              // No queryParam, reroute to redirectUrl
              return this.router.createUrlTree([redirectUrl]);
            }
          }
          // No redirectUrl, the user is just logging in
          return true;
        }
        // When user tries to login, save url user was trying to reach.
        return this.router.createUrlTree(['/login'], {
          queryParams: { redirectUrl: state.url },
        });
      })
    );
    // anchor:custom-canActivate:end
    return this.authService.validateLogin().pipe(
      map((isLoggedIn) => {
        if (isLoggedIn) {
          return true;
        }
        // TODO save redirect/return URL for later
        return this.router.createUrlTree(['/login']);
      })
    );
  }

  // @anchor:methods:start
  // @anchor:methods:end
  // anchor:custom-methods:start
  private splitRedirectUrl(redirectUrl: string) {
    let url: string;
    let parameters: string;
    const parameterObj: { [key: string]: string } = {};
    [url, parameters] = redirectUrl.split('?');
    if (parameters.includes('&')) {
      //we have more than one parameter
      const parameterList: string[] = parameters.split('&');
      parameterList.forEach((parameter) => {
        let key, value: string;
        [key, value] = parameter.split('=');
        parameterObj[key] = value;
      });
    } else {
      // We have just one parameter
      let key, value: string;
      [key, value] = parameters.split('=');
      parameterObj[key] = value;
    }
    return [url, parameterObj];
  }

  private setUserLanguage() {
    this.telephonUserService.getCurrentUser().subscribe((user) => {
      this.translateService.use(
        // Needs to be lowercase, otherwise translateService will not find the language.
        this.languageService.getLanguageCode(user.language).toLowerCase()
      );
    });
  }
  // anchor:custom-methods:end

}
