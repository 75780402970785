// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BackendConnectorService} from "~/application/gen/backend-connector.service";
import {DataElementRef} from "~/application/gen/_models/DataElementRef";
import {DisciplineLibraryPaginatedResponse, DisciplineLibraryOutputModel} from "~/components/telephondigitalComp/gen/disciplineLibrary/disciplineLibrary.api";

// @anchor:imports:start
// @anchor:imports:end
// anchor:custom-imports:start
// anchor:custom-imports:end

@Injectable({
  providedIn: 'root'
})
export class DisciplineLibraryService {

  dataElementRef: DataElementRef;

  // @anchor:variables:start
  // @anchor:variables:end
  // anchor:custom-variables:start
  // anchor:custom-variables:end

  constructor(
    private backendConnector: BackendConnectorService,
    // @anchor:services:start
    // @anchor:services:end
    // anchor:custom-services:start
    // anchor:custom-services:end
  ) {
    this.dataElementRef = {
      component: 'telephondigitalComp',
      name: 'DisciplineLibrary'
    };
    // @anchor:constructor:start
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    // anchor:custom-constructor:end
  }

  getDisciplineLibraries(): Observable<DisciplineLibraryPaginatedResponse> {
    return this.backendConnector.findDataElement<DisciplineLibraryPaginatedResponse>(this.dataElementRef, {pageSize: 100}); //pagesize is a quick and dirty fix, TODO refactor
  }

  displayDisciplineLibrary(disciplineLibrary: DisciplineLibraryOutputModel): string {
    return disciplineLibrary.externalId ?? '';
  }

  filterDisciplineLibraries(name: string, options: DisciplineLibraryOutputModel[]): DisciplineLibraryOutputModel[] {
    const filterValue = name.toLowerCase();

    return options.filter(option => option.externalId?.toLowerCase().includes(filterValue));
  }

  // @anchor:methods:start
  // @anchor:methods:end
  // anchor:custom-methods:start
  filterDisciplineLibrariesOnDisciplineName(
    name: string,
    options: DisciplineLibraryOutputModel[],
  ): DisciplineLibraryOutputModel[] {
    const filterValue = name.toLowerCase();

    return options.filter(
      (option) => option.disciplineType?.name?.toLowerCase() === filterValue,
    );
  }
  // anchor:custom-methods:end
}
