import { Component, Inject, OnInit } from '@angular/core';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { TherapyMaterialPaginatedResponse } from '~/components/telephondigitalComp/gen/therapyMaterial/_models/TherapyMaterialPaginatedResponse';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TelephonAlertService } from '~/ext/shared/services/telephonAlert.service';
import { TherapyMaterialService } from '~/components/telephondigitalComp/gen/therapyMaterial/_services/therapyMaterial.service';
import { TherapyMaterialOutputModel } from '~/components/telephondigitalComp/gen/therapyMaterial/_models/TherapyMaterialOutputModel';
import { TelephonAddMaterialDialogResult } from '~/ui/ext/telephonAddMaterialDialog/telephonAddMaterialDialogResult';
import { Pagination } from '~/application/gen/_models/Pagination';
import { PageModel } from '~/application/gen/_models/PageModel';
import { TherapyMaterialQueryParam } from '~/ext/shared/models/TherapyMaterialQueryParam';

@Component({
  selector: 'telephonAddMaterialDialog.ts',
  templateUrl: './telephonAddMaterialDialog.component.html',
  styleUrls: ['telephonAddMaterialDialog.component.scss'],
})
export class TelephonAddMaterialDialogComponent implements OnInit {
  public pageSizeOptions: number[] = [
    TelephonAddMaterialDialogComponent.DEFAULT_PAGE_SIZE,
  ];

  public publicMaterialPage: PageModel = {
    totalElements: 0,
    totalPages: 0,
    size: TelephonAddMaterialDialogComponent.DEFAULT_PAGE_SIZE,
    number: 0,
  };
  public $loadingPublicTherapyMaterialsDialogObs: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);
  public $publicTherapyMaterialsDialogObs: ReplaySubject<
    TherapyMaterialOutputModel[]
  >;
  public $hasPublicTherapyMaterialsDialogObs: ReplaySubject<boolean>;

  public privateMaterialPage: PageModel = {
    totalElements: 0,
    totalPages: 0,
    size: TelephonAddMaterialDialogComponent.DEFAULT_PAGE_SIZE,
    number: 0,
  };
  public $loadingPrivateTherapyMaterialsDialogObs: ReplaySubject<boolean> =
    new ReplaySubject<boolean>(1);
  public $privateTherapyMaterialsDialogObs: ReplaySubject<
    TherapyMaterialOutputModel[]
  >;
  public $hasPrivateTherapyMaterialsDialogObs: ReplaySubject<boolean>;

  public closeText: string;
  public dialogTitle: string;
  public dialogDescription: string;
  public saveText: string;
  public selectedTherapyMaterialExternalId?: string;
  public selectedTherapyMaterial$!: ReplaySubject<boolean[]>;

  constructor(
    private dialogRef: MatDialogRef<TelephonAddMaterialDialogComponent>,
    private therapyMaterialService: TherapyMaterialService,
    private telephonAlertService: TelephonAlertService,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.$loadingPrivateTherapyMaterialsDialogObs.next(true);
    this.$privateTherapyMaterialsDialogObs = new ReplaySubject<
      TherapyMaterialOutputModel[]
    >(1);
    this.$hasPrivateTherapyMaterialsDialogObs = new ReplaySubject<boolean>(1);

    this.$loadingPublicTherapyMaterialsDialogObs.next(true);
    this.$publicTherapyMaterialsDialogObs = new ReplaySubject<
      TherapyMaterialOutputModel[]
    >(1);
    this.$hasPublicTherapyMaterialsDialogObs = new ReplaySubject<boolean>(1);

    this.dialogTitle = <string>data.dialogTitle;
    this.dialogDescription = <string>data.dialogDescription;
    this.saveText = <string>data.saveText;
    this.closeText = <string>data.closeText;

    this.selectedTherapyMaterial$ = new ReplaySubject<boolean[]>(1);
    this.resetSelectedTherapyMaterials();
  }

  private static _DEFAULT_PAGE_SIZE: number = 8;

  static get DEFAULT_PAGE_SIZE(): number {
    return this._DEFAULT_PAGE_SIZE;
  }

  private _$privateTherapyMaterials!: Observable<TherapyMaterialPaginatedResponse>;

  get $privateTherapyMaterials(): Observable<TherapyMaterialPaginatedResponse> {
    return this._$privateTherapyMaterials;
  }

  set $privateTherapyMaterials(
    value: Observable<TherapyMaterialPaginatedResponse>,
  ) {
    this._$privateTherapyMaterials = value;
  }

  private _$publicTherapyMaterials!: Observable<TherapyMaterialPaginatedResponse>;

  get $publicTherapyMaterials(): Observable<TherapyMaterialPaginatedResponse> {
    return this._$publicTherapyMaterials;
  }

  set $publicTherapyMaterials(
    value: Observable<TherapyMaterialPaginatedResponse>,
  ) {
    this._$publicTherapyMaterials = value;
  }

  private _privateMaterialQueryParam?: TherapyMaterialQueryParam;

  get privateMaterialQueryParam(): TherapyMaterialQueryParam | undefined {
    return this._privateMaterialQueryParam;
  }

  set privateMaterialQueryParam(value: TherapyMaterialQueryParam | undefined) {
    this._privateMaterialQueryParam = value;
  }

  private _publicMaterialQueryParam?: TherapyMaterialQueryParam;

  get publicMaterialQueryParam(): TherapyMaterialQueryParam | undefined {
    return this._publicMaterialQueryParam;
  }

  set publicMaterialQueryParam(value: TherapyMaterialQueryParam | undefined) {
    this._publicMaterialQueryParam = value;
  }

  ngOnInit(): void {
    this.createPrivateTherapyMaterialsDialogObs(
      this.privateMaterialPage.number!,
      this.privateMaterialPage.size!,
      this.privateMaterialQueryParam,
    );
    this.createPublicTherapyMaterialsDialogObs(
      this.publicMaterialPage.number!,
      this.publicMaterialPage.size!,
      this.privateMaterialQueryParam,
    );
    this.updatePrivateTherapyMaterialsDialogObs();
    this.updatePublicTherapyMaterialsDialogObs();
  }

  public handlePrivateTherapyMaterialPageEvent($event: PageModel): void {
    this.privateMaterialPage = $event;
    this.createPrivateTherapyMaterialsDialogObs(
      this.privateMaterialPage.number!,
      this.privateMaterialPage.size!,
      this.privateMaterialQueryParam,
    );
    this.updatePrivateTherapyMaterialsDialogObs();
  }

  public handlePublicTherapyMaterialPageEvent($event: PageModel): void {
    this.publicMaterialPage = $event;
    this.createPublicTherapyMaterialsDialogObs(
      this.publicMaterialPage.number!,
      this.publicMaterialPage.size!,
      this.publicMaterialQueryParam,
    );
    this.updatePublicTherapyMaterialsDialogObs();
  }

  public save(): void {
    const result: string | undefined = this.selectedTherapyMaterialExternalId;
    if (this.validateResult(result)) {
      forkJoin([
        this.therapyMaterialService.getTherapyMaterialByExternalId(result!),
      ]).subscribe({
        next: ([therapyMaterial]): void => {
          this.dialogRef.close(
            new TelephonAddMaterialDialogResult(
              therapyMaterial.externalId,
              therapyMaterial.title,
            ),
          );
        },
        error: (): void => {
          this.telephonAlertService.showAlert({
            message:
              'Something went wrong getting data from the server, please try again later!',
            type: 'error',
          });
        },
      });
    } else {
      this.telephonAlertService.showAlert({
        message: 'Please select a therapyMaterial!',
        type: 'error',
      });
    }
  }

  public close(): void {
    this.dialogRef.close('cancelled');
  }

  public selectTherapyMaterial(
    therapyMaterial: TherapyMaterialOutputModel,
    index: number,
  ): void {
    this.selectedTherapyMaterialExternalId = therapyMaterial.externalId;
    let selectedTherapyMaterials: boolean[] = new Array(
      this.publicMaterialPage.size,
    );
    selectedTherapyMaterials.fill(false);
    selectedTherapyMaterials[index] = true;
    this.selectedTherapyMaterial$.next(selectedTherapyMaterials);
  }

  public searchPrivateTherapyMaterialFilter(
    $event: TherapyMaterialQueryParam,
  ): void {
    this.resetSelectedTherapyMaterials();
    this.privateMaterialQueryParam = $event;
    this.createPrivateTherapyMaterialsDialogObs(
      0,
      this.privateMaterialPage.size!,
      this.privateMaterialQueryParam,
    );
    this.updatePrivateTherapyMaterialsDialogObs();
  }

  public searchPublicTherapyMaterialFilter(
    $event: TherapyMaterialQueryParam,
  ): void {
    this.resetSelectedTherapyMaterials();
    this.publicMaterialQueryParam = $event;
    this.createPublicTherapyMaterialsDialogObs(
      0,
      this.publicMaterialPage.size!,
      this.publicMaterialQueryParam,
    );
    this.updatePublicTherapyMaterialsDialogObs();
  }

  public resetPrivateTherapyMaterialFilter(): void {
    this.privateMaterialQueryParam = undefined;
    this.createPrivateTherapyMaterialsDialogObs(
      0,
      this.publicMaterialPage.size!,
      this.privateMaterialQueryParam,
    );
    this.updatePrivateTherapyMaterialsDialogObs();
  }

  public resetPublicTherapyMaterialFilter(): void {
    this.publicMaterialQueryParam = undefined;
    this.createPublicTherapyMaterialsDialogObs(
      0,
      this.publicMaterialPage.size!,
      this.publicMaterialQueryParam,
    );
    this.updatePublicTherapyMaterialsDialogObs();
  }

  private validateResult(result: string | undefined): boolean {
    console.debug('checking: ', result);
    let valid: boolean = true;
    if (result == undefined) return !valid;
    return valid;
  }

  private resetSelectedTherapyMaterials(): void {
    let selectedTherapyMaterials: boolean[] = new Array(
      this.publicMaterialPage.size,
    );
    selectedTherapyMaterials.fill(false);
    this.selectedTherapyMaterial$.next(selectedTherapyMaterials);
  }

  private createPrivateTherapyMaterialsDialogObs(
    pageIndex: number,
    pageSize: number,
    search: TherapyMaterialQueryParam | undefined,
  ): void {
    const pagination: Pagination = {
      page: pageIndex,
      pageSize: pageSize,
    };
    if (search) {
      Object.keys(search).forEach((key: string): void => {
        if (search[key as keyof TherapyMaterialQueryParam] === undefined)
          delete search[key as keyof TherapyMaterialQueryParam];
      });
    }
    this.$privateTherapyMaterials =
      this.therapyMaterialService.getPaginatedPrivateTherapyMaterials(
        pagination,
        search,
      );
  }

  private createPublicTherapyMaterialsDialogObs(
    pageIndex: number,
    pageSize: number,
    search: TherapyMaterialQueryParam | undefined,
  ): void {
    const pagination: Pagination = {
      page: pageIndex,
      pageSize: pageSize,
    };
    if (search) {
      Object.keys(search).forEach((key: string): void => {
        if (search[key as keyof TherapyMaterialQueryParam] === undefined)
          delete search[key as keyof TherapyMaterialQueryParam];
      });
    }
    this.$publicTherapyMaterials =
      this.therapyMaterialService.getPaginatedPublicTherapyMaterials(
        pagination,
        search,
      );
  }

  private updatePrivateTherapyMaterialsDialogObs(): void {
    this.$loadingPrivateTherapyMaterialsDialogObs.next(true);
    this.$privateTherapyMaterials.subscribe({
      next: (paginatedResponse: TherapyMaterialPaginatedResponse): void => {
        this.$privateTherapyMaterialsDialogObs.next(
          paginatedResponse._embedded.therapyMaterials,
        );
        if (paginatedResponse._embedded.therapyMaterials.length == 0)
          this.$hasPrivateTherapyMaterialsDialogObs.next(false);
        else this.$hasPrivateTherapyMaterialsDialogObs.next(true);
        this.privateMaterialPage = paginatedResponse._page;
      },
      error: (err: any): void => {
        console.error(
          'Something went wrong when trying to update the page data (therapy exercises)! Error: ',
          err,
        );
      },
      complete: (): void =>
        this.$loadingPrivateTherapyMaterialsDialogObs.next(false),
    });
  }

  private updatePublicTherapyMaterialsDialogObs(): void {
    this.$loadingPublicTherapyMaterialsDialogObs.next(true);
    this.$publicTherapyMaterials.subscribe({
      next: (paginatedResponse: TherapyMaterialPaginatedResponse): void => {
        this.$publicTherapyMaterialsDialogObs.next(
          paginatedResponse._embedded.therapyMaterials,
        );
        if (paginatedResponse._embedded.therapyMaterials.length == 0)
          this.$hasPublicTherapyMaterialsDialogObs.next(false);
        else this.$hasPublicTherapyMaterialsDialogObs.next(true);
        this.publicMaterialPage = paginatedResponse._page;
      },
      error: (err: any): void => {
        console.error(
          'Something went wrong when trying to update the page data (therapy exercises)! Error: ',
          err,
        );
      },
      complete: (): void =>
        this.$loadingPublicTherapyMaterialsDialogObs.next(false),
    });
  }
}
