// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0

import { Injectable } from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {AlertComponent} from "~/ui/gen/alert/alert.component";
import {Alert} from "./_models/Alert";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private duration: number = 10000;
  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private snackBar: MatSnackBar
  ) { }

  showAlert(alert: Alert) {
    this.snackBar.openFromComponent(AlertComponent, {
      data: alert,
      duration: alert.duration ? alert.duration : this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: this.getClass(alert.type)
    })
  }

  getClass(type: string | undefined): string {
    switch (type) {
      case 'success':
        return 'alert-success';
      case 'error':
        return 'alert-error';
      case 'warning':
        return 'alert-warning';
      case 'info':
        return 'alert-info';
    }
    return '';
  }
}
