import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TelephonAddMaterialDialogComponent} from "~/ui/ext/telephonAddMaterialDialog/telephonAddMaterialDialog.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatTabsModule} from "@angular/material/tabs";
import {PaginatorModule} from "~/ui/ext/paginator/paginator.module";
import {
  TelephonTherapyMaterialFilterModule
} from "~/ui/ext/telephon-therapy-material-filter/telephon-therapy-material-filter.module";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {TranslateModule} from "@ngx-translate/core";
import {
  TelephonTherapyMaterialCardModule
} from "~/ui/ext/telephonTherapyMaterialCard/telephonTherapyMaterialCard.module";

@NgModule({
  declarations: [TelephonAddMaterialDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatTabsModule,
    ReactiveFormsModule,
    PaginatorModule,
    TelephonTherapyMaterialFilterModule,
    MatProgressBarModule,
    TranslateModule,
    TelephonTherapyMaterialCardModule,
  ],
  exports: [TelephonAddMaterialDialogComponent],
})
export class TelephonAddMaterialDialogModule {}
