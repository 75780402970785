import { Component, Inject, OnInit } from '@angular/core';
import { forkJoin, Observable, ReplaySubject, take } from 'rxjs';
import { TherapyMaterialPaginatedResponse } from '~/components/telephondigitalComp/gen/therapyMaterial/_models/TherapyMaterialPaginatedResponse';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TelephonAlertService } from '~/ext/shared/services/telephonAlert.service';
import { TherapyMaterialService } from '~/components/telephondigitalComp/gen/therapyMaterial/_services/therapyMaterial.service';
import { Pagination } from '~/application/gen/_models/Pagination';
import { TherapyMaterialQueryParam } from '~/ext/shared/models/TherapyMaterialQueryParam';
import { PageEvent } from '@angular/material/paginator';
import { TherapyMaterialOutputModel } from '~/components/telephondigitalComp/gen/therapyMaterial/_models/TherapyMaterialOutputModel';
import { TherapyMediaService } from '~/components/telephondigitalComp/gen/therapyMedia/_services/therapyMedia.service';
import { TherapyMediaPaginatedResponse } from '~/components/telephondigitalComp/gen/therapyMedia/_models/TherapyMediaPaginatedResponse';
import { TelephonAddMediaDialogResult } from '~/ui/ext/telephonAddMediaDialog/TelephonAddMediaDialogResult';

@Component({
  selector: 'telephonAddMediaDialog.ts',
  templateUrl: './telephonAddMediaDialog.component.html',
  styleUrls: ['telephonAddMediaDialog.component.scss'],
})
export class TelephonAddMediaDialogComponent implements OnInit {
  public pageIndex: number;
  public pageSize: number;
  public length?: number;
  public libraryMaterialBehaviourSubject$: ReplaySubject<TherapyMaterialPaginatedResponse>;
  public closeText: string;
  public dialogTitle: string;
  public dialogDescription: string;
  public saveText: string;
  public selectedTherapyMaterialExternalId?: string;
  public selectedTherapyMaterials$!: ReplaySubject<boolean[]>;
  public search!: string;
  private _therapyMaterials$!: Observable<TherapyMaterialPaginatedResponse>;

  constructor(
    private dialogRef: MatDialogRef<TelephonAddMediaDialogComponent>,
    private therapyMaterialService: TherapyMaterialService,
    private telephonAlertService: TelephonAlertService,
    private therapyMediaService: TherapyMediaService,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.pageIndex = 0;
    this.pageSize = 6;
    this.length = undefined;

    this.libraryMaterialBehaviourSubject$ =
      new ReplaySubject<TherapyMaterialPaginatedResponse>(1);
    this.dialogTitle = <string>data.dialogTitle;
    this.dialogDescription = <string>data.dialogDescription;
    this.saveText = <string>data.saveText;
    this.closeText = <string>data.closeText;

    this.selectedTherapyMaterials$ = new ReplaySubject<boolean[]>(1);

    this.search = '';
  }

  ngOnInit() {
    this.createDialogObs(this.pageIndex, this.pageSize, this.search);
    this.updateBehaviourSubject();
  }

  public createDialogObs(
    pageIndex: number,
    pageSize: number,
    search: string,
  ): void {
    const pagination: Pagination = {
      page: pageIndex + 1,
      pageSize: pageSize,
    };
    const queryParam: TherapyMaterialQueryParam = {
      title: search,
    };
    this._therapyMaterials$ =
      this.therapyMaterialService.getTherapyMediasAsMaterials(
        pagination,
        queryParam,
      );
  }

  public updateBehaviourSubject(): void {
    this._therapyMaterials$.pipe(take(1)).subscribe({
      next: (response: TherapyMaterialPaginatedResponse): void => {
        this.length = response._page.totalElements;
        this.libraryMaterialBehaviourSubject$.next(response);
        let selectedTherapyMaterials: boolean[] = new Array(this.pageSize);
        selectedTherapyMaterials.fill(false);
        this.selectedTherapyMaterials$.next(selectedTherapyMaterials);
        this.selectedTherapyMaterialExternalId = undefined;
      },
      error: (err: Error): void => {
        this.telephonAlertService.showAlert({
          message: `Something went wrong getting data from the backend! Error: ${err}`,
          type: 'error',
        });
      },
    });
  }

  public save(): void {
    const result: string | undefined = this.selectedTherapyMaterialExternalId;
    if (this.validateResult(result)) {
      forkJoin([
        this.therapyMediaService.getTherapyMediaByTherapyMaterialExternalId(
          result!,
        ),
        this.therapyMaterialService.getTherapyMaterialByExternalId(
          result!,
        ),
      ]).subscribe({
        next: ([therapyMedia, therapyMaterial]): void => {
          this.dialogRef.close(
            new TelephonAddMediaDialogResult(
              therapyMedia._embedded.therapyMedias[0].externalId,
              therapyMaterial.title,
            ),
          );
        },
        error: (err: Error): void => {
          this.telephonAlertService.showAlert({
            message:
              'Something went wrong getting data from the server, please try again later!',
            type: 'error',
          });
        },
      });
    } else {
      this.telephonAlertService.showAlert({
        message: 'Please select a therapyMaterial!',
        type: 'error',
      });
    }
  }

  public validateResult(result: string | undefined): boolean {
    console.debug('checking: ', result);
    let valid: boolean = true;
    if (result == undefined) return !valid;
    return valid;
  }

  public close(): void {
    this.dialogRef.close('cancelled');
  }

  public handlePageEvent($event: PageEvent): void {
    this.pageIndex = $event.pageIndex;
    this.createDialogObs(this.pageIndex, this.pageSize, this.search);
    this.updateBehaviourSubject();
  }

  public selectTherapyMaterial(
    therapyMaterial: TherapyMaterialOutputModel,
    index: number,
  ): void {
    this.selectedTherapyMaterialExternalId = therapyMaterial.externalId;
    let selectedTherapyMaterials: boolean[] = new Array(this.pageSize);
    selectedTherapyMaterials.fill(false);
    selectedTherapyMaterials[index] = true;
    this.selectedTherapyMaterials$.next(selectedTherapyMaterials);
  }

  public searchTherapyMaterial(): void {
    this.pageIndex = 0;
    this.createDialogObs(this.pageIndex, this.pageSize, this.search);
    this.updateBehaviourSubject();
  }

  public resetSearchTherapyMaterial(): void {
    this.search = '';
    this.pageIndex = 0;
    this.createDialogObs(this.pageIndex, this.pageSize, this.search);
    this.updateBehaviourSubject();
  }
}
