// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class StyleManagerService {
  public availableStyles : string[]  = [
    // anchor:custom-available-styles:start
    'telephonDigital'
    // anchor:custom-available-styles:end
  ];

  toggleTheme(newTheme : string) {
    this.availableStyles.forEach(availableStylesKey => {
      this.removeStyle(`${availableStylesKey}-theme`);
      document.body.classList.remove(`${availableStylesKey}-theme`);
    });

    const href = `${newTheme}-theme.css`;
    getLinkElementForKey(`${newTheme}-theme`).setAttribute('href', href);
    document.body.classList.add(`${newTheme}-theme`);
  }

  removeStyle(key: string) {
    const existingLinkElement = getExistingLinkElementByKey(key);
    if (existingLinkElement) {
      document.head.removeChild(existingLinkElement);
    }
  }
}

function getLinkElementForKey(key: string) {
  return getExistingLinkElementByKey(key) || createLinkElementWithKey(key);
}

function getExistingLinkElementByKey(key: string) {
  return document.head.querySelector(
    `link[rel="stylesheet"].${getClassNameForKey(key)}`
  );
}

function createLinkElementWithKey(key: string) {
  const linkEl = document.createElement('link');
  linkEl.setAttribute('rel', 'stylesheet');
  linkEl.classList.add(getClassNameForKey(key));
  document.head.appendChild(linkEl);
  return linkEl;
}

function getClassNameForKey(key: string) {
  return `style-manager-${key}`;
}

