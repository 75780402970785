import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'telephonConfirmDialog.ts',
  templateUrl: './telephonConfirmDialog.component.html',
  styleUrls: [
    './telephonConfirmDialog.component.scss',
    '../../../app.component.scss'
  ],
})
export class TelephonConfirmDialogComponent {
  accepted: boolean = true;
  declined: boolean = false;
  closeText: string;
  dialogTitle: string;
  dialogDescription: string;
  saveText: string;
  constructor(
    private dialogRef: MatDialogRef<TelephonConfirmDialogComponent>,

    @Inject (MAT_DIALOG_DATA) data: any,
  ) {
    this.dialogTitle = <string> data.dialogTitle;
    this.dialogDescription = <string> data.dialogDescription;
    this.saveText = <string> data.saveText;
    this.closeText = <string> data.closeText;
  }
  save() {
     this.dialogRef.close(this.accepted);
  }
  close() {
    this.dialogRef.close(this.declined);
  }
}
