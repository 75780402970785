<h1 style="flex-shrink: 1">{{ dialogTitle }}</h1>
<span class="description">{{ dialogDescription }}</span>
<mat-tab-group mat-align-tabs="center">
  <mat-tab label="PRIVATE LIBRARY">
    <ng-template matTabContent>
      <div class="pageWrapper" style="margin: 8px">
        <mat-card>
          <mat-card-subtitle>Therapy Materials:</mat-card-subtitle>
          <mat-card-content>
            <telephon-therapy-material-filter
              (reset)="resetPrivateTherapyMaterialFilter()"
              (search)="searchPrivateTherapyMaterialFilter($event)"
            ></telephon-therapy-material-filter>
            <div
              *ngIf="
                $privateTherapyMaterialsDialogObs
                  | async as privateTherapyMaterials;
                else loading
              "
              class="therapyTasks"
            >
              <ng-container
                *ngIf="privateTherapyMaterials.length != 0; else no_items_found"
              >
                <ng-container
                  *ngIf="
                    selectedTherapyMaterial$ | async as selectedTherapyMaterials
                  "
                >
                  <div
                    *ngFor="
                      let therapyMaterial of privateTherapyMaterials;
                      index as i
                    "
                    [style.border-radius]="
                      selectedTherapyMaterials[i] ? '8px' : null
                    "
                    [style.box-shadow]="
                      selectedTherapyMaterials[i] ? '#51c0cf 0 0 6px 1px' : null
                    "
                    class="therapyTask"
                  >
                    <telephonTherapyMaterialCard
                      (isClicked)="selectTherapyMaterial(therapyMaterial, i)"
                      [inputVM]="{
                        therapyMaterial: therapyMaterial,
                        showCrudOptions: false,
                        therapyTaskInstructions: undefined
                      }"
                      [isClickable]="true"
                    >
                    </telephonTherapyMaterialCard>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <div class="actions">
              <button
                (click)="close()"
                class="mat-raised-button_warn"
                mat-raised-button
              >
                {{ closeText }}
              </button>
              <button
                (click)="save()"
                class="mat-raised-button_primary"
                mat-raised-button
              >
                {{ saveText }}
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
        <nsx-paginator
          (page)="handlePrivateTherapyMaterialPageEvent($event)"
          [disabled]="false"
          [hidePageSize]="true"
          [pageModel]="privateMaterialPage"
          [pageSizeOptions]="pageSizeOptions"
          [showFirstLastButtons]="true"
        ></nsx-paginator>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="PUBLIC LIBRARY">
    <ng-template matTabContent>
      <div class="page-wrapper" style="margin: 8px">
        <mat-card>
          <mat-card-subtitle>Therapy Materials:</mat-card-subtitle>
          <mat-card-content>
            <telephon-therapy-material-filter
              (reset)="resetPublicTherapyMaterialFilter()"
              (search)="searchPublicTherapyMaterialFilter($event)"
            ></telephon-therapy-material-filter>
            <div
              *ngIf="
                $publicTherapyMaterialsDialogObs
                  | async as publicTherapyMaterials;
                else loading
              "
              class="therapyTasks"
            >
              <ng-container
                *ngIf="publicTherapyMaterials.length != 0; else no_items_found"
              >
                <ng-container
                  *ngIf="
                    selectedTherapyMaterial$ | async as selectedTherapyMaterials
                  "
                >
                  <div
                    *ngFor="
                      let therapyMaterial of publicTherapyMaterials;
                      index as i
                    "
                    [style.border-radius]="
                      selectedTherapyMaterials[i] ? '8px' : null
                    "
                    [style.box-shadow]="
                      selectedTherapyMaterials[i] ? '#51c0cf 0 0 6px 1px' : null
                    "
                    class="therapyTask"
                  >
                    <telephonTherapyMaterialCard
                      (isClicked)="selectTherapyMaterial(therapyMaterial, i)"
                      [inputVM]="{
                        therapyMaterial: therapyMaterial,
                        showCrudOptions: false,
                        therapyTaskInstructions: undefined
                      }"
                      [isClickable]="true"
                    >
                    </telephonTherapyMaterialCard>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <div class="actions">
              <button
                (click)="close()"
                class="mat-raised-button_warn"
                mat-raised-button
              >
                {{ closeText | uppercase }}
              </button>
              <button
                (click)="save()"
                class="mat-raised-button_primary"
                mat-raised-button
              >
                {{ saveText | uppercase }}
              </button>
            </div>
          </mat-card-actions>
        </mat-card>
        <nsx-paginator
          (page)="handlePublicTherapyMaterialPageEvent($event)"
          [disabled]="false"
          [hidePageSize]="true"
          [pageModel]="publicMaterialPage"
          [pageSizeOptions]="pageSizeOptions"
          [showFirstLastButtons]="true"
        ></nsx-paginator>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<ng-template #loading>
  <div class="animation">
    <div class="loading_spinner">
      <h1>{{ "progress.loading" | translate }}</h1>
      <mat-progress-bar color="primary" mode="buffer"></mat-progress-bar>
    </div>
  </div>
</ng-template>

<ng-template #no_items_found>
  <p>{{ "table.no_items_found" | translate }}</p>
</ng-template>
