// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '~/app.module';
import { environment } from '~env';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
