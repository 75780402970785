import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MatDialogModule} from "@angular/material/dialog";
import {
  AssignedTherapyTaskInstructionDialogComponent
} from "~/ext/assignedTherapyTaskInstructionDialog/assignedTherapyTaskInstructionDialog.component";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  declarations: [
    AssignedTherapyTaskInstructionDialogComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    AssignedTherapyTaskInstructionDialogComponent
  ]
})
export class AssignedTherapyTaskInstructionDialogModule {
}
