import { FormControl, Validators } from '@angular/forms';
import { LibraryStatus } from '~/ext/shared/enums/LibraryStatus';
import { TherapyMaterialKeywordOutputModel } from '~/components/telephondigitalComp/gen/therapyMaterialKeyword/therapyMaterialKeyword.api';

export class TherapyMaterialVM {
  constructor() {
    this.status = new FormControl<string>(LibraryStatus.IN_REVIEW, {
      validators: [Validators.required],
      nonNullable: true,
    });
    this.disciplineType = new FormControl<string | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    });
    this.subDisciplineType = new FormControl<string | undefined>(undefined, {
      nonNullable: true,
    });
    this.title = new FormControl<string | undefined>(undefined, {
      validators: [Validators.required],
      nonNullable: true,
    });
    this.regionalLanguageCode = new FormControl<string | undefined>(undefined, {
      nonNullable: true,
    });
    this.description = new FormControl<string | undefined>(undefined, {
      nonNullable: true,
    });
    this.therapyMaterialType = undefined;
    this.keywords = new FormControl<string[]>([], {
      nonNullable: true,
    });
    this.therapyMaterialKeywords = [];
  }

  private _status!: FormControl<string>; //LibraryStatus.name

  get status(): FormControl<string> {
    return this._status;
  }

  set status(value: FormControl<string>) {
    this._status = value;
  }

  private _disciplineType!: FormControl<string | undefined>; //DisciplineType.name

  get disciplineType(): FormControl<string | undefined> {
    return this._disciplineType;
  }

  set disciplineType(value: FormControl<string | undefined>) {
    this._disciplineType = value;
  }

  private _subDisciplineType!: FormControl<string | undefined>; //SubdisciplineType.name

  get subDisciplineType(): FormControl<string | undefined> {
    return this._subDisciplineType;
  }

  set subDisciplineType(value: FormControl<string | undefined>) {
    this._subDisciplineType = value;
  }

  private _title!: FormControl<string | undefined>;

  get title(): FormControl<string | undefined> {
    return this._title;
  }

  set title(value: FormControl<string | undefined>) {
    this._title = value;
  }

  private _regionalLanguageCode!: FormControl<string | undefined>; //Language.name

  get regionalLanguageCode(): FormControl<string | undefined> {
    return this._regionalLanguageCode;
  }

  set regionalLanguageCode(value: FormControl<string | undefined>) {
    this._regionalLanguageCode = value;
  }

  private _description!: FormControl<string | undefined>;

  get description(): FormControl<string | undefined> {
    return this._description;
  }

  set description(value: FormControl<string | undefined>) {
    this._description = value;
  }

  private _therapyMaterialType?: string; //TherapyMaterialType.name

  get therapyMaterialType(): string | undefined {
    return this._therapyMaterialType;
  }

  set therapyMaterialType(value: string | undefined) {
    this._therapyMaterialType = value;
  }

  private _externalId?: string;

  get externalId(): string | undefined {
    return this._externalId;
  }

  set externalId(value: string | undefined) {
    this._externalId = value;
  }

  private _keywords!: FormControl<string[]>;

  get keywords(): FormControl<string[]> {
    return this._keywords;
  }

  set keywords(value: FormControl<string[]>) {
    this._keywords = value;
  }

  private _therapyMaterialKeywords!: TherapyMaterialKeywordOutputModel[];

  get therapyMaterialKeywords(): TherapyMaterialKeywordOutputModel[] {
    return this._therapyMaterialKeywords;
  }

  set therapyMaterialKeywords(value: TherapyMaterialKeywordOutputModel[]) {
    this._therapyMaterialKeywords = value;
    this.keywords.setValue(value.map((x) => x.keyword.name!));
  }

  public markAsTouched(): void {
    this.title.markAsTouched();
    this.status.markAsTouched();
    this.disciplineType.markAsTouched();
    this.description.markAsTouched();
    this.regionalLanguageCode.markAsTouched();
    this.subDisciplineType.markAsTouched();
    this.keywords.markAsTouched();
  }
}
