import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {TelephonConfirmDialogComponent} from "~/ui/ext/telephonConfirmDialog/telephonConfirmDialog.component";

@NgModule({
  declarations: [
    TelephonConfirmDialogComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
  exports: [
    TelephonConfirmDialogComponent
  ]
})
export class TelephonConfirmDialogModule {
}
