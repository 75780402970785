import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs';
import { DisciplineLibraryService } from '~/components/telephondigitalComp/gen/disciplineLibrary/_services/disciplineLibrary.service';
import { DisciplineLibraryOutputModel } from '~/components/telephondigitalComp/gen/disciplineLibrary/disciplineLibrary.api';
import { DisciplineTypeService } from '~/components/telephondigitalComp/gen/disciplineType/_services/disciplineType.service';
import { KeywordService } from '~/components/telephondigitalComp/gen/keyword/_services/keyword.service';
import { LanguageService } from '~/components/telephondigitalComp/gen/language/_services/language.service';
import { TherapyMaterialTypeService } from '~/components/telephondigitalComp/gen/therapyMaterialType/_services/therapyMaterialType.service';
import { TherapyMaterialQueryParam } from '~/ext/shared/models/TherapyMaterialQueryParam';

type DropdownOption = { value: string; displayName: string }[];

@UntilDestroy()
@Component({
  selector: 'telephon-therapy-material-filter',
  templateUrl: './telephon-therapy-material-filter.component.html',
  styleUrls: ['./telephon-therapy-material-filter.component.scss']
})
export class TelephonTherapyMaterialFilterComponent implements OnInit {
  form: FormGroup<{
    title: FormControl<string | null>;
    language: FormControl<string | null>;
    discipline: FormControl<string | null>;
    subdiscipline: FormControl<string | null>;
    keywords: FormControl<string[] | null>;
    therapyMaterialType: FormControl<string | null>;
  }>;

  languages: DropdownOption = [];
  dsciplineLibrary: DisciplineLibraryOutputModel[] = [];
  disciplines: DropdownOption = [];
  filteredSubdisciplines: DropdownOption = [];
  keywords: DropdownOption = [];
  therapyMaterialTypes: DropdownOption = [];

  @Output('search') onSearchEventEmitter = new EventEmitter<TherapyMaterialQueryParam>();
  @Output('reset') onResetEventEmitter = new EventEmitter<void>();

  constructor(
    formBuilder: FormBuilder,
    private languageService: LanguageService,
    private disciplineLibraryService: DisciplineLibraryService,
    private disciplineTypeService: DisciplineTypeService,
    private translateService: TranslateService,
    private keywordService: KeywordService,
    private readonly therapyMaterialTypeService: TherapyMaterialTypeService
  ) {
    this.form = formBuilder.group({
      title: [''],
      language: [''],
      discipline: [''],
      subdiscipline: [{ value: '', disabled: true }],
      keywords: [[] as string[]],
      therapyMaterialType: ['']
    });
  }

  ngOnInit(): void {
    this.translateService.onLangChange.pipe(startWith(null), untilDestroyed(this)).subscribe(() => {
      this.fetchLanguages();
      this.fetchDisciplineLibrary();
      this.fetchDisciplines();
      this.fetchKeywords();
      this.fetchMaterialTypes();
    });
  }

  fetchLanguages(): void {
    this.languageService.getLanguages().subscribe({
      next: (result) => {
        this.languages = result._embedded.languages
          .map((language) => {
            return this.formatOption(language.name, 'language.');
          })
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
      }
    });
  }

  fetchDisciplineLibrary(): void {
    this.disciplineLibraryService.getDisciplineLibraries().subscribe({
      next: (result) => {
        this.dsciplineLibrary = result._embedded.disciplineLibraries;
      }
    });
  }

  fetchDisciplines(): void {
    this.disciplineTypeService.getDisciplineTypes().subscribe({
      next: (result) => {
        this.disciplines = result._embedded.disciplineTypes
          .map((discipline) => {
            return this.formatOption(discipline.name!, 'disciplineType.');
          })
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
      }
    });
  }

  fetchMaterialTypes(): void {
    this.therapyMaterialTypeService.getTherapyMaterialTypes().subscribe((result) => {
      this.therapyMaterialTypes = result._embedded.therapyMaterialTypes
        .map((therapyMaterialType) => this.formatOption(therapyMaterialType.name, 'therapyMaterialType.'))
        .sort((a, b) => a.displayName.localeCompare(b.displayName));
    });
  }

  fetchKeywords(): void {
    this.keywordService.getAllKeywords().subscribe({
      next: (result) => {
        this.keywords = result._embedded.keywords
          .map((keyword) => {
            return this.formatOption(keyword.name, 'keyword.');
          })
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
      }
    });
  }

  onKeywordRemoved(keyword: string): void {
    const filteredKeyWords = this.form
      .get('keywords')!
      .getRawValue()
      .filter((k: string) => k !== keyword);
    this.form.get('keywords')!.setValue(filteredKeyWords);
  }

  getFilteredSubdisciplines(selectedDiscipline: string): void {
    const disciplineLibrary = this.disciplineLibraryService.filterDisciplineLibrariesOnDisciplineName(
      selectedDiscipline,
      this.dsciplineLibrary
    );

    this.filteredSubdisciplines = disciplineLibrary
      .map((discipline): { value: string; displayName: string } => {
        return this.formatOption(discipline.subDisciplineType.name!, 'subDisciplineType.');
      })
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
  }

  disciplineSelected(discipline: string): void {
    this.form.get('subdiscipline')?.reset();
    this.form.get('subdiscipline')?.enable();
    this.getFilteredSubdisciplines(discipline);
  }

  searchButtonHandler() {
    const { title, language, discipline, subdiscipline, keywords, therapyMaterialType } = this.form.value;
    const queryParam: TherapyMaterialQueryParam = {};

    if (title) queryParam.title = title;
    if (language) queryParam.regionalLanguageCode = language;
    if (discipline) queryParam.disciplineType = discipline;
    if (subdiscipline) queryParam.subDisciplineType = subdiscipline;
    if (keywords?.length) queryParam.keywords = keywords;
    if (therapyMaterialType) queryParam.therapyMaterialType = therapyMaterialType;

    this.onSearchEventEmitter.emit(queryParam);
  }

  removeFilterButtonHandler() {
    this.form.reset();
    this.form.get('subdiscipline')?.disable();
    this.onResetEventEmitter.emit();
  }

  formatOption(option: string, prefix?: string): { value: string; displayName: string } {
    return {
      value: option,
      displayName: this.translateService.instant(`${prefix || ''}${option}`)
    };
  }
}
