<mat-paginator
  [disabled]="disabled"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [length]="length"
  [pageSizeOptions]="pageSizeOptions"
  [hidePageSize]="hidePageSize"
  [showFirstLastButtons]="showFirstLastButtons"
  (page)="handlePageEvent($event)"
></mat-paginator>
