<div (click)="isClickedEvent(); $event.stopPropagation()" class="material-card">
  <mat-card [class]="isClickable ? 'clickable' : ''">
    <mat-card-title-group>
      <mat-card-title>
        <span style="font-size: 75%; color: #565656">
          <span class="material-symbols-outlined">{{ getIcon() }}</span>
          {{ componentVM.therapyMaterial.title }}
        </span>
      </mat-card-title>
      <span style="margin-left: auto; font-size: 75%">
        {{
          "therapyMaterialType." +
            componentVM.therapyMaterial.therapyMaterialType!.name
            | translate
            | uppercase
        }}
      </span>
    </mat-card-title-group>
    <mat-card-content>
      <span class="material-card-title" style="font-size: 70%">
        {{ componentVM.therapyMaterial.description }}
      </span>
    </mat-card-content>
    <ng-container *ngIf="inputVM.showCrudOptions">
      <mat-card-actions>
        <div class="actions">
          <button
            *ngIf="
              componentVM.therapyMaterial.status.name !=
              LibraryStatus.PUBLIC_LIBRARY
            "
            (click)="makeTherapyMaterialPublicEvent(); $event.stopPropagation()"
            class="mat-raised-button-primary"
            mat-raised-button
            [disabled]="
              componentVM.therapyMaterial.status.name == LibraryStatus.IN_REVIEW
            "
          >
            {{ "button.make_public" | translate | uppercase }}
          </button>
          <button
            (click)="editTherapyMaterialEvent(); $event.stopPropagation()"
            class="mat-raised-button-primary"
            mat-raised-button
          >
            {{ "button.edit" | translate | uppercase }}
          </button>
          <button
            (click)="deleteTherapyMaterialEvent(); $event.stopPropagation()"
            class="mat-raised-button-warn"
            mat-raised-button
          >
            {{ "button.delete" | translate | uppercase }}
          </button>
        </div>
      </mat-card-actions>
    </ng-container>
  </mat-card>
</div>
