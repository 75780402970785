import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
  TelephonCreateAssignedTherapyTaskDialogComponent
} from "~/ui/ext/telephonCreateAssignedTherapyTaskDialog/telephonCreateAssignedTherapyTaskDialog.component";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatCardModule} from "@angular/material/card";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatIconModule} from "@angular/material/icon";
import {MatTabsModule} from "@angular/material/tabs";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    TelephonCreateAssignedTherapyTaskDialogComponent,
  ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatInputModule,
        MatCardModule,
        MatPaginatorModule,
        MatIconModule,
        FormsModule,
        MatTabsModule,
        TranslateModule,
    ],
  exports: [
    TelephonCreateAssignedTherapyTaskDialogComponent
  ]
})
export class TelephonCreateAssignedTherapyTaskDialogModule {
}
