export class ExternalStorageFileVM {
  get file(): File {
    return this._file;
  }

  set file(value: File) {
    this._file = value;
  }
  get blobContainerType(): string {
    return this._blobContainerType;
  }

  set blobContainerType(value: string) {
    this._blobContainerType = value;
  }

  private _file!: File;
  private _blobContainerType!: string;

  constructor(
    file: File,
    blobContainerType: string
  ) {
    this.file = file;
    this.blobContainerType = blobContainerType;
  }

}
