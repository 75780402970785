// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';

// @anchor:imports:start
import { TranslateService } from "@ngx-translate/core";
// @anchor:imports:end
// anchor:custom-imports:start
import {StyleManagerService} from "~/application/gen/style-manager.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatIconRegistry} from "@angular/material/icon";
// anchor:custom-imports:end

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  // @anchor:variables:start
  // @anchor:variables:end
  // anchor:custom-variables:start
  // anchor:custom-variables:end

  constructor(
    // @anchor:services:start
    private _translateService: TranslateService,
    // @anchor:services:end
    // anchor:custom-services:start
    private readonly styleManagerService: StyleManagerService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
    // anchor:custom-services:end
  ) {
    // @anchor:constructor:start
    this._translateService.addLangs([
      "en"
    ]);
    this._translateService.setDefaultLang("en");
    this._translateService.use("en");
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    this.matIconRegistry.addSvgIcon("patient", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/patient.svg"));
    this.matIconRegistry.addSvgIcon("helpdesk", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/helpdesk.svg"));
    this.matIconRegistry.addSvgIcon("account", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/account.svg"));
    this.matIconRegistry.addSvgIcon("inbox", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/inbox.svg"));
    this.matIconRegistry.addSvgIcon("home", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/home.svg"));
    this.matIconRegistry.addSvgIcon("therapist", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/therapist.svg"));

    this.matIconRegistry.addSvgIcon("getStarted", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/edit_document.svg"));
    this.matIconRegistry.addSvgIcon("add", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/add.svg"));
    this.matIconRegistry.addSvgIcon("edit_document", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/edit_document.svg"));
    this.matIconRegistry.addSvgIcon("edit_square", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/edit_square.svg"));
    this.matIconRegistry.addSvgIcon("library_books", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/library_books.svg"));
    this.matIconRegistry.addSvgIcon("panorama", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/panorama.svg"));
    this.matIconRegistry.addSvgIcon("search", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/search.svg"));
    this.matIconRegistry.addSvgIcon("edit", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/edit.svg"));
    this.matIconRegistry.addSvgIcon("delete", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/delete_forever.svg"));
    this.matIconRegistry.addSvgIcon("results", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/ext/icons/results.svg"));
    this._translateService.addLangs([
      "ca", "de", "es", "fr", "it", "nl"
    ]);
    // anchor:custom-constructor:end
  }

  ngOnInit(): void {
    // @anchor:init:start
    // @anchor:init:end
    // anchor:custom-init:start
    this.styleManagerService.toggleTheme('telephonDigital');
    // anchor:custom-init:end
  }

  ngAfterViewInit(): void {
    // @anchor:after-view-init:start
    // @anchor:after-view-init:end
    // anchor:custom-after-view-init:start
    // anchor:custom-after-view-init:end
  }

  ngOnChanges(changes: SimpleChanges): void {
    // @anchor:changes:start
    // @anchor:changes:end
    // anchor:custom-changes:start
    // anchor:custom-changes:end
  }

  ngOnDestroy(): void {
    // @anchor:destroy:start
    // @anchor:destroy:end
    // anchor:custom-destroy:start
    // anchor:custom-destroy:end
  }

  // @anchor:methods:start
  // @anchor:methods:end
  // anchor:custom-methods:start
  // anchor:custom-methods:end
}
