// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {Injectable} from '@angular/core';

import {EMPTY, Observable, of, OperatorFunction, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

import {AlertService} from "./alert.service";

import {DataElementRef} from "./_models/DataElementRef";
import {Pagination} from "./_models/Pagination";

import {Config} from "~/config.interface";
declare let __config: Config;

// @anchor:imports:start
// @anchor:imports:end
// anchor:custom-imports:start
// anchor:custom-imports:end

@Injectable({
  providedIn: 'root'
})
export class BackendConnectorService {
  private readonly httpReadHeaders = new HttpHeaders();
  private readonly httpWriteHeaders = new HttpHeaders();

  private readonly backendPath: string = __config.baseUrl + '/telephondigital';

  // @anchor:variables:start
  // @anchor:variables:end
  // anchor:custom-variables:start
  // anchor:custom-variables:end

  constructor(
    private alertService: AlertService,
    private http: HttpClient,
    // @anchor:services:start
    // @anchor:services:end
    // anchor:custom-services:start
    // anchor:custom-services:end
  ) {
    // @anchor:constructor:start
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    // anchor:custom-constructor:end
  }

  /* ----- CRUDS ----- */

  get<T>(url: string): Observable<T> {
    // @anchor:get:start
    // @anchor:get:end
    // anchor:custom-get:start
    // anchor:custom-get:end
    return this.http.get<T>(url, {headers: this.httpReadHeaders}).pipe(
      this.catchHttpError()
    );
  }

  findDataElement<T>(dataElementRef: DataElementRef, pagination?: Pagination, finder?: {[key: string]: string}): Observable<T> {
    // @anchor:find-elements:start
    // @anchor:find-elements:end
    // anchor:custom-find-elements:start
    // anchor:custom-find-elements:end

    let params = new HttpParams();
    if (finder) {
      Object.entries(finder).forEach(([key, value]) => {
        params = params.set(key, value);
      })
    }

    if (pagination) {
      if (pagination.pageSize){
        params = params.set('pagesize', String(pagination.pageSize));
      }
      if (pagination.page){
        params = params.set('page', String(pagination.page));
      }
      if (pagination.sortBy?.field) {
        const type = pagination.sortBy.ascending ? 'asc' : 'desc';
        params = params.set('sortby', `${pagination.sortBy.field}:${type}`);
      }
    }

    const url = this.generateDataElementUrl(dataElementRef);

    return this.http.get<T>(url, {
      params,
      headers: this.httpReadHeaders
    }).pipe(
      this.catchHttpError()
    );
  }

  getDataElement<T>(dataElementRef: DataElementRef, externalId: string): Observable<T> {
    // @anchor:find-one:start
    // @anchor:find-one:end
    // anchor:custom-find-one:start
    // anchor:custom-find-one:end

    let url = this.generateDataElementUrl(dataElementRef);
    url += `/${externalId}`;

    return this.http.get<T>(url, {headers: this.httpReadHeaders}).pipe(
      this.catchHttpError()
    );
  }

  post<T, R>(dataElementRef: DataElementRef, postInputModel: T): Observable<R> {
    // @anchor:post:start
    // @anchor:post:end
    // anchor:custom-post:start
    // anchor:custom-post:end

    let url = this.generateDataElementUrl(dataElementRef);

    return this.http.post<R>(url, postInputModel, {headers: this.httpWriteHeaders}).pipe(
      this.catchHttpError()
    );
  }

  put<T, R>(dataElementRef: DataElementRef, externalId: string, putInputModel: T): Observable<R> {
    // @anchor:put:start
    // @anchor:put:end
    // anchor:custom-put:start
    // anchor:custom-put:end

    let url = this.generateDataElementUrl(dataElementRef);
    url += `/${externalId}`;

    return this.http.put<R>(url, putInputModel, {headers: this.httpWriteHeaders}).pipe(
      this.catchHttpError()
    );
  }

  delete<R>(dataElementRef: DataElementRef, externalId: string): Observable<R> {
    // @anchor:delete:start
    // @anchor:delete:end
    // anchor:custom-delete:start
    // anchor:custom-delete:end

    let url = this.generateDataElementUrl(dataElementRef);
    url += `/${externalId}`;

    return this.http.delete<R>(url, {headers: this.httpWriteHeaders}).pipe(
      this.catchHttpError()
    );
  }

  /* ----- HANDLE ERROR ----- */

  private catchHttpError<T>(): OperatorFunction<T, T> {
    return catchError((err: unknown) => {
      if (!(err instanceof HttpErrorResponse)) {
        return throwError(err);
      }

      const errorStr = err.error?.title ?? 'alert.error.general';
      this.alertService.showAlert({
        message: errorStr,
        type: 'error'
      })
      return throwError(new Error());
    });
  }

  /* ----- GENERATE URL ----- */

  private generateDataElementUrl(dataElementRef: DataElementRef) {
    const connectorPath = this.connectorPathMapping.get(dataElementRef.component + '::' + dataElementRef.name);

    let url = `${this.getBackendPath(dataElementRef.component)}${connectorPath}`;

    // @anchor:generate-url:start
    // @anchor:generate-url:end
    // anchor:custom-generate-url:start
    // anchor:custom-generate-url:end

    return url;
  }

  /* ----- MAPPING ----- */

  getBackendPath(component: string): string {
    return this.backendPath + this.componentPathMapping.get(component);
  }

  private readonly componentPathMapping: Map<string, string> = new Map([
    ['telephondigitalComp', '/v1'],
  ]);

  private readonly connectorPathMapping: Map<string, string> = new Map([
    ['telephondigitalComp::AssignedTherapyExerciseStepExecutionAnswer', '/assignedtherapyexercisestepexecutionanswers'],
    ['telephondigitalComp::AssignedTherapyPath', '/assignedtherapypaths'],
    ['telephondigitalComp::AssignedTherapyTask', '/assignedtherapytasks'],
    ['telephondigitalComp::AssignedTherapyTaskExecution', '/assignedtherapytaskexecutions'],
    ['telephondigitalComp::AssignedTherapyTaskInstruction', '/assignedtherapytaskinstruction'],
    ['telephondigitalComp::ChatMessage', '/chatmessages'],
    ['telephondigitalComp::ChatMessageStatus', '/chatmessagestatuses'],
    ['telephondigitalComp::ClinicalObservation', '/Clinicalobservation'],
    ['telephondigitalComp::DifficultyEvaluationType', '/difficultyevaluationtypes'],
    ['telephondigitalComp::DisciplineLibrary', '/disciplinelibraries'],
    ['telephondigitalComp::DisciplineType', '/disciplinetypes'],
    ['telephondigitalComp::ExerciseLevelType', '/exerciseleveltypes'],
    ['telephondigitalComp::ExerciseStepAnswerType', '/exercisestepanswertypes'],
    ['telephondigitalComp::ExerciseStepType', '/exercisesteptypes'],
    ['telephondigitalComp::ExerciseTools', '/exerciseToolses'],
    ['telephondigitalComp::ExternalStorageFile', '/externalstoragefiles'],
    ['telephondigitalComp::FileContentType', '/filecontenttypes'],
    ['telephondigitalComp::GenderType', '/gendertypes'],
    ['telephondigitalComp::HelpDeskRequest', '/helpdeskrequests'],
    ['telephondigitalComp::IndividualTest', '/individualTests'],
    ['telephondigitalComp::IndividualTestType', '/individualtesttypes'],
    ['telephondigitalComp::Keyword', '/keywords'],
    ['telephondigitalComp::Language', '/languages'],
    ['telephondigitalComp::LibraryStatus', '/librarystatuses'],
    ['telephondigitalComp::Notification', '/notifications'],
    ['telephondigitalComp::NotificationType', '/notificationtypes'],
    ['telephondigitalComp::OpenAnswerType', '/openanswertypes'],
    ['telephondigitalComp::SubdisciplineType', '/subdisciplinetypes'],
    ['telephondigitalComp::SubmittedAnswer', '/submittedanswerses'],
    ['telephondigitalComp::TelephonConfiguration', '/telephonconfigurations'],
    ['telephondigitalComp::TelephonConfigurationType', '/telephonconfigurationtypes'],
    ['telephondigitalComp::TelephonUser', '/telephonusers'],
    ['telephondigitalComp::TelephonUserReductionCode', '/telephonuserreductioncodes'],
    ['telephondigitalComp::TelephonUserType', '/telephonusertypes'],
    ['telephondigitalComp::TherapistPatientRelation', '/therapistpatientrelations'],
    ['telephondigitalComp::TherapistPatientRelationStatus', '/therapistpatientrelationstatuses'],
    ['telephondigitalComp::TherapyDocument', '/therapydocuments'],
    ['telephondigitalComp::TherapyExercise', '/therapyexercises'],
    ['telephondigitalComp::TherapyExerciseAnswerOption', '/therapyExerciseansweroptions'],
    ['telephondigitalComp::TherapyExerciseStep', '/therapyExercisesteps'],
    ['telephondigitalComp::TherapyExerciseStepTip', '/therapyExerciseSteptips'],
    ['telephondigitalComp::TherapyExerciseType', '/therapyexercisetypes'],
    ['telephondigitalComp::TherapyMaterial', '/therapymaterials'],
    ['telephondigitalComp::TherapyMaterialKeyword', '/therapymaterialkeywords'],
    ['telephondigitalComp::TherapyMaterialType', '/therapymaterialtypes'],
    ['telephondigitalComp::TherapyMedia', '/therapyMedias'],
    ['telephondigitalComp::TherapyPathStageType', '/therapypathstagetypes'],
    ['telephondigitalComp::TherapyTask', '/therapytasks'],
    ['telephondigitalComp::TherapyTaskExecutionProgressType', '/therapytaskexecutionprogresstypes'],
    ['telephondigitalComp::TherapyTaskInstruction', '/therapytaskinstructions'],
    ['telephondigitalComp::UsedTips', '/usedtipses'],

    // anchor:custom-connectorPathMapping:start
    // anchor:custom-connectorPathMapping:end
  ]);

  // @anchor:methods:start
  // @anchor:methods:end
  // anchor:custom-methods:start
  patch<T, R>(
    dataElementRef: DataElementRef,
    externalId: string,
    patchInputModel: T,
    appendage: string,
  ): Observable<R> {
    let url = this.generateDataElementUrl(dataElementRef);
    url += `/${externalId}`;
    url += appendage;

    return this.http
      .patch<R>(url, patchInputModel, { headers: this.httpWriteHeaders })
      .pipe(this.catchHttpError());
  }

  querySearchDataElement<T>(
    dataElementRef: DataElementRef,
    pagination?: Pagination,
    queryParam?: { [key: string]: string | string[]},
    extension?: string
  ): Observable<T> {
    let params = new HttpParams();

    if (queryParam) {
      Object.entries(queryParam).forEach(([key, value]) => {
        if (value instanceof Array) {
          value.forEach((v) => {
            params = params.append(key, v);
          });
          return;
        }
        params = params.set(key, value);
      });
    }

    if (pagination) {
      if (pagination.pageSize) {
        params = params.set('pagesize', String(pagination.pageSize));
      }
      if (pagination.page) {
        params = params.set('page', String(pagination.page));
      }
      if (pagination.sortBy?.field) {
        const type = pagination.sortBy.ascending ? 'asc' : 'desc';
        params = params.set('sortby', `${pagination.sortBy.field}:${type}`);
      }
    }

    let url: string = this.generateDataElementUrl(dataElementRef);

    if (extension) {
      url = url.concat(extension);
    }

    return this.http
      .get<T>(url, {
        params,
        headers: this.httpReadHeaders,
      })
      .pipe(this.catchHttpError());
  }

  customPost<T, R>(
    dataElementRef: DataElementRef,
    postInputModel: T,
    urlAddition?: string,
  ): Observable<R> {
    let url = this.generateDataElementUrl(dataElementRef);
    if (urlAddition) {
      url += urlAddition;
    }

    return this.http
      .post<R>(url, postInputModel, { headers: this.httpWriteHeaders })
      .pipe(this.catchHttpError());
  }

  extendedGetDataElement<T>(
    dataElementRef: DataElementRef,
    urlAddition?: string,
  ): Observable<T> {
    let url = this.generateDataElementUrl(dataElementRef);
    url += `/${urlAddition}`;
    return this.http
      .get<T>(url, { headers: this.httpReadHeaders })
      .pipe(this.catchHttpError());
  }

  // anchor:custom-methods:end
}
