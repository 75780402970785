<div class="parent">
  <h2 mat-dialog-title>{{dialogTitle}}</h2>
  <div mat-dialog-content>
    <p>{{dialogDescription}}</p>
  </div>
  <div mat-dialog-actions>
    <button (click)="close()" class="mat-raised-button_corr" color="primary" mat-raised-button>{{closeText}}</button>
    <button (click)="save()" class="mat-raised-button_warn" color="primary" mat-raised-button>{{saveText}}</button>
  </div>
</div>
