import { Injectable } from '@angular/core';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {TelephonAlertComponent} from "~/ui/ext/telephonAlert/telephonAlert.component";
import {Alert} from "../../../application/gen/_models/Alert";

@Injectable({
  providedIn: 'root'
})
export class TelephonAlertService {

  private duration: number = 10000;
  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    private snackBar: MatSnackBar
  ) { }

  showAlert(alert: Alert) {
    this.snackBar.openFromComponent(TelephonAlertComponent, {
      data: alert,
      duration: alert.duration ? alert.duration : this.duration,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: this.getClass(alert.type)
    })
  }

  getClass(type: string | undefined): string {
    switch (type) {
      case 'success':
        return 'alert-success';
      case 'error':
        return 'alert-error';
      case 'warning':
        return 'alert-warning';
      case 'info':
        return 'alert-info';
    }
    return '';
  }
}
