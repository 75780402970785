import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LibraryStatus } from '~/ext/shared/enums/LibraryStatus';
import { TelephonTherapyMaterialCardComponentVM } from '~/ui/ext/telephonTherapyMaterialCard/_models/TelephonTherapyMaterialCardComponentVM';

@Component({
  selector: 'telephonTherapyMaterialCard',
  templateUrl: './telephonTherapyMaterialCard.component.html',
  styleUrls: ['./telephonTherapyMaterialCard.component.scss'],
})
export class TelephonTherapyMaterialCardComponent implements OnInit {
  @Input() public inputVM!: TelephonTherapyMaterialCardComponentVM;
  @Input() public isClickable!: boolean;
  @Output() public publicEvent: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public editEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() public deleteEvent: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public isClicked: EventEmitter<string> = new EventEmitter<string>();
  public componentVM!: TelephonTherapyMaterialCardComponentVM;

  protected readonly LibraryStatus = LibraryStatus;

  ngOnInit(): void {
    this.componentVM = {
      therapyMaterial: this.inputVM.therapyMaterial,
      showCrudOptions: this.inputVM.showCrudOptions,
      therapyTaskInstructions: this.inputVM.therapyTaskInstructions,
    };
  }

  getIcon(): string {
    if (this.componentVM.therapyMaterial.therapyMaterialType?.name == 'VIDEO')
      return 'movie';
    else if (
      this.componentVM.therapyMaterial.therapyMaterialType?.name == 'IMAGE'
    )
      return 'image';
    else if (
      this.componentVM.therapyMaterial.therapyMaterialType?.name == 'AUDIO'
    )
      return 'headphones';
    else if (
      this.componentVM.therapyMaterial.therapyMaterialType?.name == 'PDF'
    )
      return 'picture_as_pdf';
    else if (
      this.componentVM.therapyMaterial.therapyMaterialType?.name == 'DOCUMENT'
    )
      return 'article';
    else if (
      this.componentVM.therapyMaterial.therapyMaterialType?.name == 'EXERCISE'
    )
      return 'quiz';
    else return 'questionMark';
  }

  public editTherapyMaterialEvent(): void {
    this.editEvent.emit(this.componentVM.therapyMaterial.externalId);
  }

  public deleteTherapyMaterialEvent(): void {
    this.deleteEvent.emit(this.componentVM.therapyMaterial.externalId);
  }

  public makeTherapyMaterialPublicEvent(): void {
    this.publicEvent.emit(this.componentVM.therapyMaterial.externalId);
  }

  public isClickedEvent(): void {
    this.isClicked.emit(this.componentVM.therapyMaterial.externalId);
  }
}
