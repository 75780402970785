import {Injectable} from "@angular/core";
import {Socket} from "ngx-socket-io";
import {Config} from "~/config.interface";
declare let __config: Config;
@Injectable()
export class CallSocketService extends Socket {
  constructor() {
    super({
      url: __config.socketServerBaseUrl + "/call",
      options: {
        autoConnect: false
      }
    });
  }
}
