// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BackendConnectorService} from "~/application/gen/backend-connector.service";
import {DataElementRef} from "~/application/gen/_models/DataElementRef";
import {TherapyTaskPaginatedResponse, TherapyTaskOutputModel} from "~/components/telephondigitalComp/gen/therapyTask/therapyTask.api";

// @anchor:imports:start
// @anchor:imports:end
// anchor:custom-imports:start
import { LibraryStatus } from '~/ext/shared/enums/LibraryStatus';
import { Pagination } from '~/application/gen/_models/Pagination';
import { TherapyMaterialQueryParam } from '~/ext/shared/models/TherapyMaterialQueryParam';
import { TherapyTaskVM } from '~/pages/ext/createTask/classes/TherapyTaskVM';
// anchor:custom-imports:end

@Injectable({
  providedIn: 'root'
})
export class TherapyTaskService {

  dataElementRef: DataElementRef;

  // @anchor:variables:start
  // @anchor:variables:end
  // anchor:custom-variables:start
  // anchor:custom-variables:end

  constructor(
    private backendConnector: BackendConnectorService,
    // @anchor:services:start
    // @anchor:services:end
    // anchor:custom-services:start
    // anchor:custom-services:end
  ) {
    this.dataElementRef = {
      component: 'telephondigitalComp',
      name: 'TherapyTask'
    };
    // @anchor:constructor:start
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    // anchor:custom-constructor:end
  }

  getTherapyTasks(): Observable<TherapyTaskPaginatedResponse> {
    return this.backendConnector.findDataElement<TherapyTaskPaginatedResponse>(this.dataElementRef, {pageSize: 100}); //pagesize is a quick and dirty fix, TODO refactor
  }

  displayTherapyTask(therapyTask: TherapyTaskOutputModel): string {
    return therapyTask.externalId ?? '';
  }

  filterTherapyTasks(name: string, options: TherapyTaskOutputModel[]): TherapyTaskOutputModel[] {
    const filterValue = name.toLowerCase();

    return options.filter(option => option.externalId?.toLowerCase().includes(filterValue));
  }

  // @anchor:methods:start
  // @anchor:methods:end
  // anchor:custom-methods:start
  public getTherapyTaskByExternalId(externalId: string): Observable<TherapyTaskOutputModel> {
    return this.backendConnector.getDataElement<TherapyTaskOutputModel>(
      this.dataElementRef,
      externalId,
    );
  }
  getTherapyTasksInPrivateLibrary<T>(
    page: number,
    pageSize: number,
    search: string,
  ): Observable<T> {
    if (search != '') {
      return this.backendConnector.querySearchDataElement<T>(
        this.dataElementRef,
        {
          page: page,
          pageSize: pageSize,
        },
        {
          ['libraryStatus']: LibraryStatus.PRIVATE_LIBRARY,
          ['title']: search,
        },
      );
    } else {
      return this.backendConnector.querySearchDataElement<T>(
        this.dataElementRef,
        {
          page: page,
          pageSize: pageSize,
        },
        {
          ['libraryStatus']: LibraryStatus.PRIVATE_LIBRARY,
        },
      );
    }
  }

  public getTherapyTasksOnSearch(
    pagination?: Pagination,
    queryParam?: TherapyMaterialQueryParam,
  ): Observable<TherapyTaskPaginatedResponse> {
    const queryParamComb = {
      ...queryParam,
    };

    return this.backendConnector.querySearchDataElement<TherapyTaskPaginatedResponse>(
      this.dataElementRef,
      pagination,
      queryParamComb,
    );
  }

  postTherapyTask<TherapyTaskPostInputModel>(
    inputModel: TherapyTaskPostInputModel,
  ): Observable<any> {
    return this.backendConnector.post<TherapyTaskPostInputModel, any>(
      this.dataElementRef,
      inputModel,
    );
  }

  deleteTherapyTaskByExternalId(
    therapyTaskExternalId: string,
  ): Observable<any> {
    return this.backendConnector.delete<any>(
      this.dataElementRef,
      therapyTaskExternalId,
    );
  }

  patchTherapyTask<T, R>(
    therapyTaskExternalId: string,
    inputModel: T,
  ): Observable<R> {
    return this.backendConnector.patch<T, R>(
      this.dataElementRef,
      therapyTaskExternalId,
      inputModel,
      '',
    );
  }
  // anchor:custom-methods:end
}
