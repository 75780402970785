// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserModel } from "~/application/gen/_models/UserModel";

import {Config} from "~/config.interface";
declare let __config: Config;

// @anchor:imports:start
import { filter } from 'rxjs';
import { Router } from "@angular/router";
import { OAuthErrorEvent, OAuthEvent, OAuthInfoEvent, OAuthService } from "angular-oauth2-oidc";
import { environment } from "~env";
// @anchor:imports:end
// anchor:custom-imports:start
// anchor:custom-imports:end

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly authenticationType: string;

  // @anchor:variables:start
  public get currentUser(): UserModel | undefined {
    let claims = this.oAuthService.getIdentityClaims();
    if(!claims) return;
    return {
      name: claims['preferred_username'],
      profile: {
        name: "user"
      }
    }
  };
  private isAuthenticated: boolean = false;
  private isDoneLoading: boolean = false;
  // @anchor:variables:end
  // anchor:custom-variables:start
  // anchor:custom-variables:end

  constructor(
    // @anchor:services:start
    private router: Router,
    private oAuthService: OAuthService,
    // @anchor:services:end
    // anchor:custom-services:start
    // anchor:custom-services:end
  ) {
    // @anchor:constructor:start
    //set AuthenticationType
    this.authenticationType = 'Bearer';

    //check if Debugging is enabled in the environment file and it's not a production build
    if((environment.isDebuggingEnabled??false) && !environment.production){
      this.logOIDCEventsToConsole();
    }

    //listen to storage changes for the access token
    window.addEventListener('storage', (event) => {
      // Checking if there's a storage event for key 'access_token'
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }
      // Noticed changes to storage with key 'access_token' (most likely from another tab)
      // recheck if authentication is still valid;
      this.isAuthenticated = this.oAuthService.hasValidAccessToken();
      //if it isn't valid anymore, return to login
      if(!this.oAuthService.hasValidAccessToken()){
        this.navigateToLoginPage();
      }
    });
    // check if we have a valid token right now
    this.isAuthenticated = this.oAuthService.hasValidAccessToken();
    // whenever an event happens, check if there is still a valid token
    this.oAuthService.events.subscribe(
      () => {
        this.isAuthenticated = this.oAuthService.hasValidAccessToken();
      }
    );
    // check whenever [token_received] changes, load the user with that token
    this.oAuthService.events
      .pipe(filter((event: OAuthEvent) => ['token_received'].includes(event.type)))
      .subscribe(() => this.oAuthService.loadUserProfile());

    // check whenever session changes happen, redirect to login page to reAuth the user
    this.oAuthService.events
      .pipe(filter((event: OAuthEvent) => ['session_terminated', 'session_error'].includes(event.type)))
      .subscribe(() => this.navigateToLoginPage());

    // setup token refresh after certain intervals
    this.oAuthService.setupAutomaticSilentRefresh();
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    // anchor:custom-constructor:end
  }

  validateLogin(): Observable<boolean> {
    // @anchor:validate-login:start
    return of(this.isAuthenticated && this.isDoneLoading);
    // @anchor:validate-login:end
    // anchor:custom-validate-login:start
    // anchor:custom-validate-login:end
  }

  getAuthorizationHeader(): string | null {
    let token: string | null = null;
    // @anchor:get-auth-header:start
    if (this.oAuthService.hasValidAccessToken()){
      token = this.oAuthService.getAccessToken();
    }
    // @anchor:get-auth-header:end
    // anchor:custom-get-auth-header:start
    // anchor:custom-get-auth-header:end
    if (!token || !this.authenticationType) {
      return null;
    }
    return [this.authenticationType,  token].join(' ');
  }

  // @anchor:methods:start
  public login(targetUrl?: string): void {
    this.oAuthService.initLoginFlow(targetUrl || this.router.url);
  }
  public logout(): void {
    this.oAuthService.logOut();
  }
  public refresh(): void {
    this.oAuthService.silentRefresh();
  }
  public runInitialLoginSequence(): Promise<boolean|void> {
    // anchor:custom-initial-sequence:start
    // anchor:custom-initial-sequence:end
    return this.oAuthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      this.isDoneLoading = true;
    });
  }
  public navigateToLoginPage(): void {
    this.router.navigateByUrl('/login');
  }
  private logOIDCEventsToConsole(): void {
    this.oAuthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthErrorEvent Object:', event);
      } else if (event instanceof OAuthInfoEvent){
        console.info('OAuthInfoEvent Object:', event);
      } else {
        console.warn('OAuthInfoEvent Object:', event);
      }
    });
  }
  // @anchor:methods:end
  // anchor:custom-methods:start
  // anchor:custom-methods:end
}
