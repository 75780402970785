import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup} from "@angular/forms";
import {Observable, ReplaySubject, take} from "rxjs";
import {TherapyTaskService} from "~/components/telephondigitalComp/gen/therapyTask/_services/therapyTask.service";
import {
  TherapyTaskPaginatedResponse
} from "~/components/telephondigitalComp/gen/therapyTask/_models/TherapyTaskPaginatedResponse";
import {PageEvent} from "@angular/material/paginator";
import {TelephonAlertService} from "~/ext/shared/services/telephonAlert.service";
import {TherapyTaskOutputModel} from "~/components/telephondigitalComp/gen/therapyTask/_models/TherapyTaskOutputModel";
import {
  AssignedTherapyTaskPostInputModel
} from "~/components/telephondigitalComp/gen/assignedTherapyTask/_models/AssignedTherapyTaskPostInputModel";
import {
  TherapyTaskInstructionService
} from '~/components/telephondigitalComp/gen/therapyTaskInstruction/_services/therapyTaskInstruction.service';

@Component({
  selector: 'telephonCreateAssignedTherapyTaskDialog.ts',
  templateUrl: './telephonCreateAssignedTherapyTaskDialog.component.html',
  styleUrls: [
    './telephonCreateAssignedTherapyTaskDialog.component.scss'
  ],
})
export class TelephonCreateAssignedTherapyTaskDialogComponent implements OnInit {
  public pageIndex: number;
  public pageSize: number;
  public length?: number;
  public todayDate: Date;
  public privateLibraryTherapyTasksBehaviourSubject$: ReplaySubject<TherapyTaskPaginatedResponse>;
  public closeText: string;
  public dialogTitle: string;
  public dialogDescription: string;
  public saveText: string;
  public startDate = new FormGroup({
    start: new FormControl<Date | null>(null)
  });
  public selectedTherapyTaskExternalid?: string;
  public selectedTherapyTasks$!: ReplaySubject<boolean[]>;
  public assignedTherapyPathExternalId!: string;
  public therapyPathStageTypeName!: string;
  public search!: string;
  private _privateLibraryTherapyTasks$!: Observable<TherapyTaskPaginatedResponse>;

  constructor(
    private dialogRef: MatDialogRef<TelephonCreateAssignedTherapyTaskDialogComponent>,
    private therapyTaskService: TherapyTaskService,
    private telephonAlertService: TelephonAlertService,
    private therapyTaskInstructionService: TherapyTaskInstructionService,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.pageIndex = 0;
    this.pageSize = 6;
    this.length = undefined;
    this.todayDate = new Date();

    this.privateLibraryTherapyTasksBehaviourSubject$ = new ReplaySubject<TherapyTaskPaginatedResponse>(1);
    this.dialogTitle = <string>data.dialogTitle;
    this.dialogDescription = <string>data.dialogDescription;
    this.saveText = <string>data.saveText;
    this.closeText = <string>data.closeText;

    this.assignedTherapyPathExternalId = <string>data.assignedTherapyPathExternalId;
    this.therapyPathStageTypeName = <string>data.therapyPathStageTypeName;
    this.selectedTherapyTasks$ = new ReplaySubject<boolean[]>(1);

    this.search = '';
  }

  ngOnInit() {
    this.createDialogObs(this.pageIndex, this.pageSize, this.search);
    this.updateBehaviourSubject();
  }

  createDialogObs(pageIndex: number, pageSize: number, search: string) {
    this._privateLibraryTherapyTasks$ = this.therapyTaskService
      .getTherapyTasksInPrivateLibrary<TherapyTaskPaginatedResponse>(pageIndex + 1, pageSize, search);
  }

  updateBehaviourSubject() {
    this._privateLibraryTherapyTasks$.pipe(take(1)).subscribe({
      next: (emit: TherapyTaskPaginatedResponse) => {
        this.length = emit._page.totalElements;
        this.privateLibraryTherapyTasksBehaviourSubject$.next(emit);
        let selectedTherapyTasks = new Array(this.pageSize);
        selectedTherapyTasks.fill(false);
        this.selectedTherapyTasks$.next(selectedTherapyTasks);
        this.selectedTherapyTaskExternalid = undefined;
      },
      error: (err) => {
        this.telephonAlertService.showAlert({
          message: `Something went getting task data from backend! Error: ${err}`,
          type: 'error'
        });
      }
    });
  }

  save() {
    if (this.startDate.getRawValue().start === null) {
      this.telephonAlertService.showAlert({
        message: `Please select valid date!`,
        type: 'error'
      });
    } else {
      this.startDate.getRawValue().start!.setHours(8,0,0,0);
      const result = {
        startDate: new Date(this.startDate.getRawValue().start!.toUTCString()),
        therapyTask: this.selectedTherapyTaskExternalid,
        assignedTherapyPath: this.assignedTherapyPathExternalId,
        therapyPathStageType: this.therapyPathStageTypeName,
      } as AssignedTherapyTaskPostInputModel;
      if (this.validateResult(result)) {
        this.dialogRef.close(result);
      } else {
        this.telephonAlertService.showAlert({
          message: `Please select valid task and date!`,
          type: 'error'
        });
      }
    }
  }

  validateResult(result: AssignedTherapyTaskPostInputModel): boolean {
    console.debug("checking: ", result);
    let valid: boolean = true;
    if (result.startDate == null) return !valid;
    if (result.therapyTask == undefined) return !valid;
    if (result.assignedTherapyPath == undefined) return !valid;
    if (result.therapyPathStageType == undefined) return !valid;
    return valid;
  }

  close() {
    this.dialogRef.close("cancelled");
  }

  handlePageEvent($event: PageEvent) {
    this.pageIndex = $event.pageIndex;
    this.createDialogObs(this.pageIndex, this.pageSize, this.search);
    this.updateBehaviourSubject();
  }

  selectTherapyTask(therapyTask: TherapyTaskOutputModel, index: number) {
    this.selectedTherapyTaskExternalid = therapyTask.externalId;
    let selectedTherapyTasks = new Array(this.pageSize);
    selectedTherapyTasks.fill(false);
    selectedTherapyTasks[index] = true;
    this.selectedTherapyTasks$.next(selectedTherapyTasks);
  }

  searchTherapyTask() {
    this.pageIndex = 0;
    this.createDialogObs(this.pageIndex, this.pageSize, this.search);
    this.updateBehaviourSubject();
  }

  resetSearchTherapyTask() {
    this.search = '';
    this.pageIndex = 0;
    this.createDialogObs(this.pageIndex, this.pageSize, this.search);
    this.updateBehaviourSubject();
  }
}
