import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'telephonFormDialog',
  templateUrl: './telephonFormDialog.component.html',
  styleUrls: [
    './telephonFormDialog.component.scss',
    '../../../app.component.scss'
  ],
})
export class TelephonFormDialogComponent {
  closeText: string;
  dialogTitle: string;
  dialogDescription: string;
  dialogForms: FormGroup;
  dialogFormsControlName: string[];
  dialogFormsPlaceholder: string[];
  dialogFormsErrorText: string[];
  saveText: string;
  iterator: number[];
  constructor(
    private dialogRef: MatDialogRef<TelephonFormDialogComponent>,

    @Inject (MAT_DIALOG_DATA) data: any,
  ) {

    this.dialogTitle = <string> data.dialogTitle;
    this.dialogDescription = <string> data.dialogDescription;
    this.dialogForms = <FormGroup<any>> data.dialogForms;
    this.dialogFormsControlName = <string[]> data.dialogFormsControlName;
    this.dialogFormsPlaceholder = <string[]> data.dialogFormsPlaceholder;
    this.dialogFormsErrorText = <string[]> data.dialogFormsErrorText;
    this.saveText = <string> data.saveText;
    this.closeText = <string> data.closeText;

    this.iterator = Array(this.dialogFormsControlName.length).fill(1).map((x,i)=>i);
  }
  save() {
    if (this.dialogForms.invalid) {
      // If invalid mark as touched to display error messages
      this.dialogForms.markAllAsTouched();
    }
    else {
     this.dialogRef.close(this.dialogForms?.value);
    }
  }
  close() {
    this.dialogRef.close();
  }

  invalidForm() {
    return true;
  }
}
