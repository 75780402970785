import { NumberInput } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { PageModel } from '~/application/gen/_models/PageModel';
import { Pagination } from '~/application/gen/_models/Pagination';

@Component({
  selector: 'nsx-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() pageModel!: PageModel;
  @Input() pageSizeOptions: number[];
  @Input() hidePageSize: boolean;
  @Input() showFirstLastButtons: boolean;
  @Input() disabled: boolean;

  @Output() page: EventEmitter<PageModel> = new EventEmitter();

  constructor() {
    this.pageSizeOptions = [5, 10, 25, 100];
    this.hidePageSize = false;
    this.showFirstLastButtons = false;
    this.disabled = false;
  }

  ngOnInit(): void {
    if (!this.pageModel) {
      console.error('PaginatorComponent: pageModel is required');
    }
  }

  handlePageEvent(event: PageEvent) {
    this.pageModel.number = event.pageIndex + 1;
    this.pageModel.size = event.pageSize;
    this.page.emit(this.pageModel);
  }

  get pagination(): Pagination {
    return {
      page: this.pageModel.number,
      pageSize: this.pageModel.size,
    };
  }

  get pageIndex(): NumberInput {
    return this.pagination.page
      ? this.pagination.page - 1
      : this.pagination.page;
  }

  get length(): NumberInput {
    return this.pageModel.totalElements;
  }

  get pageSize(): NumberInput {
    return this.pagination.pageSize;
  }
}
