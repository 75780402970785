// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BackendConnectorService} from "~/application/gen/backend-connector.service";
import {DataElementRef} from "~/application/gen/_models/DataElementRef";
import {TherapyMaterialPaginatedResponse, TherapyMaterialOutputModel} from "~/components/telephondigitalComp/gen/therapyMaterial/therapyMaterial.api";

// @anchor:imports:start
// @anchor:imports:end
// anchor:custom-imports:start
import { Pagination } from '~/application/gen/_models/Pagination';
import { TherapyMaterialQueryParam } from '~/ext/shared/models/TherapyMaterialQueryParam';
import { TherapyMediaService } from '~/components/telephondigitalComp/gen/therapyMedia/_services/therapyMedia.service';
// anchor:custom-imports:end

@Injectable({
  providedIn: 'root'
})
export class TherapyMaterialService {

  dataElementRef: DataElementRef;

  // @anchor:variables:start
  // @anchor:variables:end
  // anchor:custom-variables:start
  // anchor:custom-variables:end

  constructor(
    private backendConnector: BackendConnectorService,
    // @anchor:services:start
    // @anchor:services:end
    // anchor:custom-services:start
    private readonly therapyMediaService: TherapyMediaService,
    // anchor:custom-services:end
  ) {
    this.dataElementRef = {
      component: 'telephondigitalComp',
      name: 'TherapyMaterial'
    };
    // @anchor:constructor:start
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    // anchor:custom-constructor:end
  }

  getTherapyMaterials(): Observable<TherapyMaterialPaginatedResponse> {
    return this.backendConnector.findDataElement<TherapyMaterialPaginatedResponse>(this.dataElementRef, {pageSize: 100}); //pagesize is a quick and dirty fix, TODO refactor
  }

  displayTherapyMaterial(therapyMaterial: TherapyMaterialOutputModel): string {
    return therapyMaterial.name ?? '';
  }

  filterTherapyMaterials(name: string, options: TherapyMaterialOutputModel[]): TherapyMaterialOutputModel[] {
    const filterValue = name.toLowerCase();

    return options.filter(option => option.name?.toLowerCase().includes(filterValue));
  }

  // @anchor:methods:start
  // @anchor:methods:end
  // anchor:custom-methods:start
  public getTherapyMediasAsMaterials(
    pagination?: Pagination,
    queryParam?: TherapyMaterialQueryParam,
  ): Observable<TherapyMaterialPaginatedResponse> {
    const queryParamType: { [key: string]: string } = {
      ['therapyMaterialType']: 'MEDIA',
    };
    const hideDisabled: { [key: string]: string } = {
      ['hideDisabled']: 'true',
    };
    const queryParamComb: { [key: string]: string | string[] } = {
      ...hideDisabled,
      ...queryParamType,
      ...queryParam,
    };
    return this.backendConnector.querySearchDataElement<TherapyMaterialPaginatedResponse>(
      this.dataElementRef,
      pagination,
      queryParamComb,
    );
  }

  public getTherapyDocumentsAsMaterials(
    pagination?: Pagination,
    queryParam?: TherapyMaterialQueryParam,
  ): Observable<TherapyMaterialPaginatedResponse> {
    const queryParamType: { [key: string]: string } = {
      ['therapyMaterialType']: 'DOCUMENT',
    };
    const hideDisabled: { [key: string]: string } = {
      ['hideDisabled']: 'true',
    };
    const queryParamComb: { [key: string]: string | string[] } = {
      ...hideDisabled,
      ...queryParamType,
      ...queryParam,
    };
    return this.backendConnector.querySearchDataElement<TherapyMaterialPaginatedResponse>(
      this.dataElementRef,
      pagination,
      queryParamComb,
    );
  }

  public getTherapyExercisesAsMaterials(
    pagination?: Pagination,
    queryParam?: TherapyMaterialQueryParam,
  ): Observable<TherapyMaterialPaginatedResponse> {
    const queryParamType: { [key: string]: string } = {
      ['therapyMaterialType']: 'EXERCISE',
    };
    const hideDisabled: { [key: string]: string } = {
      ['hideDisabled']: 'true',
    };
    const queryParamComb: { [key: string]: string | string[] } = {
      ...hideDisabled,
      ...queryParamType,
      ...queryParam,
    };
    return this.backendConnector.querySearchDataElement<TherapyMaterialPaginatedResponse>(
      this.dataElementRef,
      pagination,
      queryParamComb,
    );
  }

  public getPublicTherapyMaterials(
    pagination?: Pagination,
    queryParam?: TherapyMaterialQueryParam,
  ): Observable<TherapyMaterialPaginatedResponse> {
    return this.backendConnector.querySearchDataElement<TherapyMaterialPaginatedResponse>(
      this.dataElementRef,
      pagination,
      {
        ['status']: 'PUBLIC_LIBRARY',
        ['hideDisabled']: 'true',
        ...queryParam,
      },
    );
  }

  getPrivateTherapyMaterials(
    pagination?: Pagination,
    queryParam?: TherapyMaterialQueryParam,
  ): Observable<TherapyMaterialPaginatedResponse> {
    return this.backendConnector.querySearchDataElement<TherapyMaterialPaginatedResponse>(
      this.dataElementRef,
      pagination,
      {
        ['status']: 'PRIVATE_LIBRARY',
        ['hideDisabled']: 'true',
        ...queryParam,
      },
    );
  }

  public getTherapyMaterialByExternalId(externalId: string): Observable<TherapyMaterialOutputModel> {
    return this.backendConnector.getDataElement<TherapyMaterialOutputModel>(
      this.dataElementRef,
      externalId,
    );
  }

  public getPaginatedPublicTherapyMaterials(
    pagination?: Pagination,
    queryParam?: TherapyMaterialQueryParam
  ): Observable<TherapyMaterialPaginatedResponse> {
    const queryParamType: { [key: string]: string } = {
      ['status']: 'PUBLIC_LIBRARY',
    };
    const queryParamComb: { [key: string]: string | string[] } = {
      ...queryParamType,
      ['hideDisabled']: 'true',
      ...queryParam,
    };
    return this.backendConnector.querySearchDataElement<TherapyMaterialPaginatedResponse>(
      this.dataElementRef,
      pagination,
      queryParamComb,
    );
  }

  public getPaginatedPrivateTherapyMaterials(
    pagination?: Pagination,
    queryParam?: TherapyMaterialQueryParam
  ): Observable<TherapyMaterialPaginatedResponse> {
    const queryParamType: { [key: string]: string } = {
      ['status']: 'PRIVATE_LIBRARY',
    };
    const queryParamComb: { [key: string]: string | string[] } = {
      ...queryParamType,
      ['hideDisabled']: 'true',
      ...queryParam,
    };
    return this.backendConnector.querySearchDataElement<TherapyMaterialPaginatedResponse>(
      this.dataElementRef,
      pagination,
      queryParamComb,
    );
  }

  postTherapyMaterial<TherapyMaterialPostInputModel>(
    inputModel: TherapyMaterialPostInputModel,
  ): Observable<any> {
    return this.backendConnector.post<TherapyMaterialPostInputModel, any>(
      this.dataElementRef,
      inputModel,
    );
  }

  putTherapyMaterial<TherapyMaterialPutInputModel>(
    inputModel: TherapyMaterialPutInputModel,
    externalId: string,
  ) {
    return this.backendConnector.put<TherapyMaterialPutInputModel, any>(
      this.dataElementRef,
      externalId,
      inputModel,
    );
  }

  deleteTherapyMaterialWithId(externalId: string): Observable<any> {
    return this.backendConnector.delete<any>(this.dataElementRef, externalId);
  }

  public patchTherapyMaterialByExternalId<T, R>(
    therapyMaterialExternalId: string,
    patchInpuModel: T,
  ): Observable<R> {
    return this.backendConnector.patch<T, R>(
      this.dataElementRef,
      therapyMaterialExternalId,
      patchInpuModel,
      '',
    );
  }

  // anchor:custom-methods:end
}
