import { NgModule } from '@angular/core';
import { TelephonTherapyMaterialCardComponent } from '~/ui/ext/telephonTherapyMaterialCard/telephonTherapyMaterialCard.component';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TelephonTherapyMaterialCardComponent],
  imports: [CommonModule, MatCardModule, MatButtonModule, TranslateModule],
  exports: [TelephonTherapyMaterialCardComponent],
})
export class TelephonTherapyMaterialCardModule {}
