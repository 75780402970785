export class TelephonAddMediaDialogResult {
  private _mediaExternalId : string;
  private _mediaTitle: string;

  constructor(mediaExternalId: string, mediaTitle: string) {
    this._mediaExternalId = mediaExternalId;
    this._mediaTitle = mediaTitle;
  }


  get mediaExternalId(): string {
    return this._mediaExternalId;
  }

  set mediaExternalId(value: string) {
    this._mediaExternalId = value;
  }

  get mediaTitle(): string {
    return this._mediaTitle;
  }

  set mediaTitle(value: string) {
    this._mediaTitle = value;
  }
}
