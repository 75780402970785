// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
export const environment = {
  production: false,
  authInterceptExclusions: [
    // @anchor:authInterceptExclusions:start
    // @anchor:authInterceptExclusions:end
    // anchor:custom-authInterceptExclusions:start
    // anchor:custom-authInterceptExclusions:end
  ],
  // @anchor:properties:start
  isDebuggingEnabled: true,
  // @anchor:properties:end
  // anchor:custom-properties:start
  // anchor:custom-properties:end
};
