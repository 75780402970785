<!-- expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0 -->

<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content>{{data.message}}</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-raised-button [color]="data.type === 'delete' ? 'warn' : 'primary'" [mat-dialog-close]="true">
    {{data.type | titlecase}}
  </button>
</mat-dialog-actions >
