// expanded with nsx-expanders:5.32.1, expansionResource org.normalizedsystems.angular:angular-expanders:3.2.0
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BackendConnectorService} from "~/application/gen/backend-connector.service";
import {DataElementRef} from "~/application/gen/_models/DataElementRef";
import {TherapyMaterialTypePaginatedResponse, TherapyMaterialTypeOutputModel} from "~/components/telephondigitalComp/gen/therapyMaterialType/therapyMaterialType.api";

// @anchor:imports:start
// @anchor:imports:end
// anchor:custom-imports:start
// anchor:custom-imports:end

@Injectable({
  providedIn: 'root'
})
export class TherapyMaterialTypeService {

  dataElementRef: DataElementRef;

  // @anchor:variables:start
  // @anchor:variables:end
  // anchor:custom-variables:start
  // anchor:custom-variables:end

  constructor(
    private backendConnector: BackendConnectorService,
    // @anchor:services:start
    // @anchor:services:end
    // anchor:custom-services:start
    // anchor:custom-services:end
  ) {
    this.dataElementRef = {
      component: 'telephondigitalComp',
      name: 'TherapyMaterialType'
    };
    // @anchor:constructor:start
    // @anchor:constructor:end
    // anchor:custom-constructor:start
    // anchor:custom-constructor:end
  }

  getTherapyMaterialTypes(): Observable<TherapyMaterialTypePaginatedResponse> {
    return this.backendConnector.findDataElement<TherapyMaterialTypePaginatedResponse>(this.dataElementRef, {pageSize: 100}); //pagesize is a quick and dirty fix, TODO refactor
  }

  displayTherapyMaterialType(therapyMaterialType: TherapyMaterialTypeOutputModel): string {
    return therapyMaterialType.name ?? '';
  }

  filterTherapyMaterialTypes(name: string, options: TherapyMaterialTypeOutputModel[]): TherapyMaterialTypeOutputModel[] {
    const filterValue = name.toLowerCase();

    return options.filter(option => option.name?.toLowerCase().includes(filterValue));
  }

  // @anchor:methods:start
  // @anchor:methods:end
  // anchor:custom-methods:start
  // anchor:custom-methods:end
}
